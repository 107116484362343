import React from "react";
import "./Popup.scss";
import Loader from "../Loader";
import SubmitButton from "../SubmitButton";

const Popup = ({
  isOpen,
  onClose,
  title,
  formFields,
  onInputChange,
  onSubmit,
  loading
}: any) => {
  const handleInputChange = (id: any, e: any) => {
    onInputChange(id, e.target.value);
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    onSubmit(); // Call the parent's onSubmit handler
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <form className="popup-form" onSubmit={handleFormSubmit}>
          <h2>{title}</h2>
          {formFields.map((field: any) => (
            <div key={field.id} className="form-field">
              <label>
                {field.label}:
                {field.type === "text" && (
                  <input
                    type="text"
                    name={field.name}
                    value={field.value}
                    onChange={(e) => handleInputChange(field.id, e)}
                    required={field.required}
                  />
                )}
                {field.type === "select" && (
                  <select
                    name={field.name}
                    value={field.value}
                    onChange={(e) => handleInputChange(field.id, e)}
                    required={field.required}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    {field.options.map((option: any, index: any) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                )}
              </label>
            </div>
          ))}
          <SubmitButton isLoading={loading} onClick={() => handleFormSubmit}>
            Submit
          </SubmitButton>
        </form>
      </div>
    </div>
  );
};

export default Popup;
