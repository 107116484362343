import React, { useState, useEffect } from "react";
import "./typewriter-animation-container.scss";

interface TypewriterProps {
	message: string;
	speed?: number; // Speed in milliseconds between each character
}

const TypewriterAnimation: React.FC<TypewriterProps> = ({
	message,
	speed = 50,
}) => {
	const [displayedText, setDisplayedText] = useState("");

	useEffect(() => {
		if (!message) return;

		setDisplayedText(""); // Reset state at the start

		let currentIndex = 0;
		const type = () => {
			if (currentIndex < message.length) {
				const charToAdd = message[currentIndex] || ""; // Prevent undefined
				setDisplayedText((prev) => {
					const updatedText = prev + charToAdd;
					return updatedText;
				});
				currentIndex++;
				setTimeout(type, speed);
			}
		};

		type();
	}, [message, speed]);

	return <div>{displayedText}</div>;
};

export default TypewriterAnimation;
