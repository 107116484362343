import React, { useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-charts-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

const AgGridTable = (props: any) => {
	const {
		gridOptions,
		gridRef,
		domLayout,
		pagination,
		paginationPageSize,
		paginationPageSizeSelector,
		columnDefs,
		defaultColDef,
		rowModelType,
		rowGroupPanelShow,
		groupDisplayType,
		serverSideSortAllLevels,
		suppressAggFuncInHeader,
		enableCharts,
		paginateChildRows,
		enableRangeSelection,
		postMasterTableAgGrid,
		transformRows,
		sideBar,
		agGridMasterApiCall,
	} = props;

	const datasource = {
		async getRows(params: any) {
			try {
				// postAgGrid will change from table to table as AG Grid doesn't allow you to pass datasource / onGridReady as props
				// Would it be best to send a function from parent component - sending params.request - doing the API call in parent and returning response to child?

				const response = await agGridMasterApiCall(params.request);
				const transformedResponse = transformRows(response);

				params.success({
					rowData: transformedResponse.rows,
					lastRow: transformedResponse.lastRow,
				});
			} catch (error) {
				console.error("Error fetching rows:", error);
				params.fail();
			}
		},
	};

	const onGridReady = (params: any) => {
		params.api.setGridOption("serverSideDatasource", datasource);
		const columnsToolPanel = params.api.getToolPanelInstance("columns");
		columnsToolPanel.collapseColumnGroups();
	};

	return (
		<div style={{ height: "1050px", width: "100%" }}>
			<div
				style={{ height: "1050px", width: "100%" }}
				className={"ag-theme-quartz"}
			>
				<AgGridReact
					gridOptions={gridOptions}
					ref={gridRef}
					domLayout={domLayout}
					pagination={pagination}
					paginationPageSize={paginationPageSize}
					paginationPageSizeSelector={paginationPageSizeSelector}
					columnDefs={columnDefs}
					defaultColDef={defaultColDef}
					rowModelType={rowModelType}
					onGridReady={onGridReady}
					rowGroupPanelShow={rowGroupPanelShow}
					groupDisplayType={groupDisplayType}
					serverSideSortAllLevels={serverSideSortAllLevels}
					suppressAggFuncInHeader={suppressAggFuncInHeader}
					enableCharts={enableCharts}
					paginateChildRows={paginateChildRows}
					enableRangeSelection={enableRangeSelection}
					sideBar={sideBar}
				/>
			</div>
		</div>
	);
};

export default AgGridTable;
