import React, { useMemo, useRef, useState, useEffect } from "react";

import "./master-table-container.scss";

import { postMasterTableAgGrid } from "../../../services/report.service";
import { transformRows } from "../../../utils/ag-grid-transformers";
import { applyFormatters } from "../../../utils/ag-grid-helpers";

import AgGridTable from "../../../components/AgGridTable";

const MasterTable = () => {
	const gridRef = useRef();

	const [colDefs, setColDefs] = useState([]);

	const agGridMasterApiCall = async (params: any) => {
		const response = await postMasterTableAgGrid(params);
		const processedColumns = applyFormatters(response.data.colDef);
		setColDefs(processedColumns);
		return response.data;
	};

	const sideBar: any = useMemo(() => {
		return {
			toolPanels: [
				{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel",
					minWidth: 225,
					maxWidth: 225,
					width: 225,
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivotMode: true,
						suppressColumnSelectAll: true,
					},
				},
			],
			position: "left",
			defaultToolPanel: "columns",
			hiddenByDefault: false,
		};
	}, []);

	const defaultColDef = useMemo(
		() => ({
			sortable: false,
			flex: 1,
			minWidth: 100,
			filterParams: {
				maxNumConditions: 1,
				buttons: ["apply", "reset"],
			},
		}),
		[]
	);

	const gridOptions = useMemo(
		() => ({
			autoGroupColumnDef: {
				// sortable: false,
				// filter: false,
			},
		}),
		[]
	);

	return (
		<AgGridTable
			postMasterTableAgGrid={postMasterTableAgGrid}
			transformRows={transformRows}
			gridRef={gridRef}
			columnDefs={colDefs}
			defaultColDef={defaultColDef}
			gridOptions={gridOptions}
			rowModelType={"serverSide"}
			pagination={true}
			paginationPageSize={20}
			paginationPageSizeSelector={[10, 20, 50, 100]}
			paginateChildRows={true}
			rowGroupPanelShow={"always"}
			groupDisplayType={"multipleColumns"}
			enableCharts={true}
			enableRangeSelection={true}
			serverSideSortAllLevels={true}
			suppressAggFuncInHeader={true}
			sideBar={sideBar}
			agGridMasterApiCall={agGridMasterApiCall}
		/>
	);
};

export default MasterTable;
