import React, { useState } from "react";
import "./SigninPage.scss";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { appInsights } from "../../services/app-insights.service";
import { useAuth } from "../../context/auth.context";
import SubmitButton from "../../components/SubmitButton";
import Logo from "../../components/Logo";

const SigninPage = ({ setToken }: any) => {
	const { login } = useAuth();
	const navigate = useNavigate();
	const [username, setUserName] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const clearState = () => {
		setUserName("");
		setPassword("");
	};
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");

	async function handleLogin(email: string, password: string) {
		appInsights.trackEvent({
			name: "ButtonClicked",
			properties: {
				buttonName: "LoginButton",
				user: `${localStorage.getItem("email")}`,
			},
		});
		try {
			setIsLoading(true);
			await login(email, password); // Attempt to log in
			navigate("/");
		} catch (error: any) {
			setIsLoading(false);
			var errorText = error.response?.data?.errors?.username[0];
			if (errorText) {
				setError("Invalid username or password.");
			}
			clearState();
		}
		setLoading(false);
	}

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		setError("");
		if (username == undefined || password == undefined) {
			setError("Both username and password are required");
			return;
		}

		setLoading(true);
		await handleLogin(username, password);
	};

	return (
		<div className="signin">
			<Logo />
			<form onSubmit={handleSubmit}>
				<div className="form-group">
					<input
						value={username}
						type="text"
						id="username"
						className="form-input"
						placeholder=" "
						onChange={(e) => setUserName(e.target.value)}
					/>
					<label className="form-label">Username</label>
				</div>

				<div className="form-group">
					<input
						value={password}
						type="password"
						id="password"
						className="form-input"
						placeholder=" "
						onChange={(e) => setPassword(e.target.value)}
					/>
					<label className="form-label">Password</label>
				</div>

				<p className="error" style={{ marginBottom: "12px" }}>
					{error}
				</p>

				<SubmitButton isLoading={isLoading} onClick={() => handleSubmit}>
					Log In
				</SubmitButton>

				<Link className="forgot-password" to="/forgot-password">
					Forgot Password?
				</Link>
			</form>
		</div>
	);
};

export default SigninPage;
