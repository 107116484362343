import React from 'react';
import './Logo.scss';
import defaultLogo from '../../assets/logo/goodwork-ai_logo_POS_Large_Green.png';

interface LogoProps {
  src?: string; // Optional: Path to the logo image
  alt?: string; // Alt text for the logo
  width?: string; // Optional width (default is 50%)
  height?: string; // Optional height (default is auto)
}

const Logo: React.FC<LogoProps> = ({
  src = defaultLogo, // Default to the imported logo
  alt = 'GoodWork AI Logo',
  width = '50%',
  height = 'auto',
}) => {
  return <img className="logo" src={src} alt={alt} style={{ width, height }} />;
};

export default Logo;
