import React from "react";
import "./example-question-container.scss";
const ExampleQuestionContainer = ({ text, onClick }: any) => {
	return (
		<div className="example-question" onClick={() => onClick(text)}>
			{text}
		</div>
	);
};

export default ExampleQuestionContainer;
