import React, { useState, useCallback, useEffect } from "react";
import "./details-container.scss";
import SectionTitle from "../SectionTitle";
import AgGridStaticTable from "../AgGridStaticTable";
import {
	salesColDefs,
	salesTableRows,
	salesDataPointsTopAg,
} from "../../routes/Report/reportData";
import { transformRows } from "../../utils/ag-grid-transformers";
import WaterfallChart from "../WaterfallChart";
import AgWaterfallChart from "../AgWaterfallChart";

const style = {
	marginLeft: "-25px",
	borderBottom: "1px solid #e6ebe9",
};
const DetailsContainer = ({
	title,
	detailsHeading,
	detailsSubheading,
	dataPointsTop,
	dataPointsBottom,
	chart,
}: any) => {
	const [showTable, setShowTable] = useState(false);
	const [colDefs, setColDefs]: any = useState([]);

	const agGridMasterApiCall = (params: any) => {
		const response = salesColDefs;
		setColDefs(response);
		return salesTableRows;
	};

	const handleWaterfallBarClick = () => {
		setShowTable(!showTable);
	};

	const getRowStyle = useCallback(
		(params: any) => {
			if (params.node.rowIndex === salesTableRows.length - 1) {
				return {
					backgroundColor: "#E6EBE9",
					fontWeight: "bold",
				};
			}
			return undefined;
		},
		[salesTableRows]
	);

	return (
		<div className="details-container">
			<SectionTitle title={title} style={style} />
			<div className="details-container__content">
				<div className="details-container__heading">{detailsHeading}</div>
				<div className="details-container__subheading">{detailsSubheading}</div>
				<WaterfallChart
					handleWaterfallBarClick={handleWaterfallBarClick}
					dataPoints={dataPointsTop}
					chart={`${chart}Top`}
				/>
				<div className={`details-container__gap ${chart}`}>
					<div className={`details-container__gap-1-${chart}`} />
					<div className={`details-container__gap-2-${chart}`} />
					<div className={`details-container__gap-3-${chart}`} />
					<div className={`details-container__gap-4-${chart}`} />
				</div>
				<WaterfallChart
					handleWaterfallBarClick={handleWaterfallBarClick}
					dataPoints={dataPointsBottom}
					chart={`${chart}Bottom`}
				/>

				{/* {salesDataPointsTopAg && (
					<AgWaterfallChart options={waterfallOptions} />
				)} */}

				{showTable && (
					<div className="table-container">
						<AgGridStaticTable
							// gridRef={gridRef}
							columnDefs={colDefs}
							// defaultColDef={defaultColDef}
							// gridOptions={gridOptions}
							domLayout={"autoHeight"}
							transformRows={transformRows}
							rowModelType={"serverSide"}
							pagination={true}
							paginationPageSize={20}
							paginationPageSizeSelector={[10, 20, 50, 100]}
							paginateChildRows={true}
							rowGroupPanelShow={"none"}
							groupDisplayType={"multipleColumns"}
							enableCharts={true}
							enableRangeSelection={true}
							serverSideSortAllLevels={true}
							suppressAggFuncInHeader={true}
							agGridMasterApiCall={agGridMasterApiCall}
							getRowStyle={getRowStyle}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default DetailsContainer;
