import React, { useState } from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Link,
	useLocation,
} from "react-router-dom";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/app-insights.service";
import { useTrackPageViews } from "../services/app-insights.service";
import { useAuth } from "../context/auth.context";
import { Navigate } from "react-router-dom";

import "./routes-container.scss";

import SigninPage from "./SigninPage/SigninPage";
import Home from "./Home";
import InputPage from "./InputPage";
import Nav from "../components/Nav/Nav";
import Report from "./Report";
import ResetPassword from "./ResetPassword";
import ForgotPassword from "./ForgotPassword";
import TestSignalR from "./TestSignalR";
import Admin from "./Admin";

// ProtectedRoute component for v6
const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
	const { isAuthenticated } = useAuth();

	if (!isAuthenticated) {
		return <Navigate to="/login" replace />;
	}

	return children;
};

const AppRoutes = () => {
	const { isAuthenticated } = useAuth();
	const location = useLocation();

	const [collapseNav, setCollapseNav] = useState(true);
	const [chatGPTModel, setChatGPTModel]: any = useState("gpt4o");

	useTrackPageViews();

	const publicRoutes = ["/login", "/reset-password", "/forgot-password"];
	const isPublicRoute = publicRoutes.includes(location.pathname);

	const handleNavChange = (change: any) => {
		setCollapseNav(!change);
	};

	return (
		<>
			{!isPublicRoute && (
				<Nav setCollapseNav={handleNavChange} collapseNav={collapseNav} />
			)}
			<div
				className={`routes ${isPublicRoute ? "public-route" : ""}
                            ${!isPublicRoute && collapseNav
						? "collapsed-nav"
						: ""
					} 
                            ${!isPublicRoute && !collapseNav
						? "expanded-nav"
						: ""
					}`}
			>
				<Routes>
					{/* Public Routes */}
					<Route path="/login" element={<SigninPage />} />
					<Route path="/reset-password" element={<ResetPassword />} />
					<Route path="/forgot-password" element={<ForgotPassword />} />

					{/* Private Routes */}
					<Route
						path="/"
						element={
							<ProtectedRoute>
								<InputPage
									chatGPTModel={chatGPTModel}
									setChatGPTModel={setChatGPTModel}
								/>
							</ProtectedRoute>
						}
					/>
					<Route
						path="/report"
						element={
							<ProtectedRoute>
								<Report />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/report/:reportId"
						element={
							<ProtectedRoute>
								<Report />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/home"
						element={
							<ProtectedRoute>
								<Home />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/admin"
						element={
							<ProtectedRoute>
								<Admin />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/webhook"
						element={
							<ProtectedRoute>
								<TestSignalR />
							</ProtectedRoute>
						}
					/>
				</Routes>
			</div>
		</>
	);
};

export default withAITracking(reactPlugin, AppRoutes);
