import React, { useState } from "react";
import "./driver-block-container.scss";

import chevronDownIcon from "../../assets/icons/chevron-down.svg";

const DriverBlock = ({
	heading,
	paragraph,
	children,
	variationDirection,
}: any) => {
	const [showContent, setShowContent] = useState(false);
	return (
		<div
			className="driver-block"
			onClick={() => setShowContent((prev) => !prev)}
			style={{
				cursor: variationDirection === null ? "" : "pointer",
				pointerEvents: variationDirection === null ? "none" : "all",
			}}
		>
			<div
				className="driver-block__parent"
				style={{
					borderColor:
						variationDirection === null
							? ""
							: variationDirection === "-"
							? "rgba(193, 37, 23, 0.16)"
							: "rgba(42, 156, 99, 0.2)",
					backgroundColor:
						variationDirection === null
							? ""
							: variationDirection === "-"
							? "rgba(193, 37, 23, 0.08)"
							: "rgba(42, 156, 99, 0.1)",
				}}
			>
				<img
					src={chevronDownIcon}
					alt={"down arrow"}
					className={
						!showContent ? "driver-block__arrow" : "driver-block__arrow active"
					}
				/>

				<div className="driver-block__content">
					<div className="driver-block__heading">{heading}</div>
					<div className="driver-block__paragraph">{paragraph}</div>
				</div>
			</div>
			{variationDirection && showContent && (
				<div className="driver-block__details-content">{children}</div>
			)}
		</div>
	);
};

export default DriverBlock;
