import React from "react";

import "./report-title-container.scss";

const ReportTitle = (props: any) => {
	const { text } = props;
	return (
		<div className="report-title">
			<h1 className="report-title__h1">{text}</h1>
		</div>
	);
};

export default ReportTitle;
