export const priceFormatter = (params: any) => {
	if (params.value != null) {
		const roundedValue = Math.round(Number(params.value) * 100) / 100;

		return `$${roundedValue.toLocaleString("en-US", {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		})}`;
	}
	return "$0.00";
};

export const percentageFormatter = (params: any) => {
	if (params.value != null) {
		const roundedValue = Math.round(Number(params.value) * 1000) / 1000;

		return `${roundedValue}%`;
	}
	return "0%";
};

export const decimalFormatter = (params: any) => {
	if (params.value != null) {
		const roundedValue = Math.round(Number(params.value) * 1000) / 1000;

		return `${roundedValue}`;
	}
	return "0";
};

export const colorFormatter: any = (params: any) => {
	if (params.value > 0) {
		return { color: "green" };
	} else if (params.value < 0) {
		return { color: "red" };
	} else {
		return null;
	}
};

export const arrowIndicator = (params: any): JSX.Element => {
	const { value, valueFormatted } = params;

	const getIndicator = () => {
		if (value > 0) {
			return { color: "green", symbol: "▲" }; // Up arrow
		} else if (value < 0) {
			return { color: "red", symbol: "▼" }; // Down arrow
		} else {
			return { color: "inherit", symbol: "—" }; // Neutral dash
		}
	};

	const { color, symbol } = getIndicator();

	return (
		<span style={{ color }}>
			{symbol} {valueFormatted}
		</span>
	);
};
export const cogsArrowIndicator = (params: any): JSX.Element => {
	const { value, valueFormatted } = params;

	const getIndicator = () => {
		if (value < 0) {
			return { color: "green", symbol: "▲" }; // Up arrow
		} else if (value > 0) {
			return { color: "red", symbol: "▼" }; // Down arrow
		} else {
			return { color: "inherit", symbol: "—" }; // Neutral dash
		}
	};

	const { color, symbol } = getIndicator();

	return (
		<span style={{ color }}>
			{symbol} {valueFormatted}
		</span>
	);
};

const formatterMap: any = {
	priceFormatter,
	percentageFormatter,
	decimalFormatter,
	colorFormatter,
	arrowIndicator,
};

export const applyFormatters = (colDefs: any) => {
	return colDefs.map((col: any) => {
		const newCol = { ...col };
		if (
			typeof newCol.valueFormatter === "string" &&
			formatterMap[newCol.valueFormatter]
		) {
			newCol.valueFormatter = formatterMap[newCol.valueFormatter];
		}
		if (
			typeof newCol.cellRenderer === "string" &&
			formatterMap[newCol.cellRenderer]
		) {
			newCol.cellRenderer = formatterMap[newCol.cellRenderer];
		}
		if (
			typeof newCol.cellStyle === "string" &&
			formatterMap[newCol.cellStyle]
		) {
			newCol.cellStyle = formatterMap[newCol.cellStyle];
		}
		if (Array.isArray(newCol.children)) {
			newCol.children = applyFormatters(newCol.children);
		}

		return newCol;
	});
};
