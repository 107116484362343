import React from "react";
import "./selection-bubble-container.scss";

import analyseIcon from "../../assets/icons/pie-chart.svg";
import timeIcon from "../../assets/icons/calendar.svg";
import filterIcon from "../../assets/icons/filter-lines.svg";
import breakdownIcon from "../../assets/icons/bar-line-chart.svg";

const SelectionBubble = ({ icon, text, selectedObject }: any) => {
	// Check if selectedObject is valid
	if (
		!selectedObject ||
		!selectedObject.category ||
		!selectedObject.category[0]
	) {
		return null;
	}

	// Conditional rendering for "time" category
	if (selectedObject.category[0] === "time") {
		// Exclude specific names
		const excludedNames = ["year", "ref_year"];
		if (excludedNames.includes(selectedObject.name)) {
			return null;
		}

		return (
			<div className="selection-bubble">
				<img src={timeIcon} className="selection-bubble__icon" />
				<div className="selection-bubble__text">{text}</div>
			</div>
		);
	}

	// Default rendering for other categories
	const iconMap: Record<string, string> = {
		product_filter: analyseIcon,
		other_filters: filterIcon,
		time: timeIcon,
		breakdown: breakdownIcon,
	};

	return (
		<div className="selection-bubble">
			<img
				src={iconMap[icon] || ""}
				className="selection-bubble__icon"
				alt={`${icon}-icon`}
			/>
			<div className="selection-bubble__text">{text}</div>
		</div>
	);
};

export default SelectionBubble;
