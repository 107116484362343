import React from "react";
import "./parent-accordion-container.scss";

import analyseIcon from "../../assets/icons/pie-chart.svg";
import tableIcon from "../../assets/icons/table.svg";

enum AccordionType {
	Analytics = "Analytics",
	MasterDataTable = "MasterDataTable",
}

const ParentAccordion = ({ text, icon, onClick, showContent, type }: any) => {
	icon =
		icon ?? type === AccordionType.Analytics
			? analyseIcon
			: type === AccordionType.MasterDataTable
			? tableIcon
			: undefined;
	text =
		text ?? type === AccordionType.Analytics
			? "Analysis"
			: type === AccordionType.MasterDataTable
			? "Master Data Table"
			: "";

	return (
		<div className="parent-accordion" onClick={() => onClick()}>
			<img src={icon} alt={`${text} icon`} className="parent-accordion__icon" />
			<div className="parent-accordion__text">{text}</div>
			<div
				className={
					!showContent
						? "parent-accordion__arrow"
						: "parent-accordion__arrow active"
				}
			>
				&rsaquo;
			</div>
		</div>
	);
};

export { AccordionType };
export default ParentAccordion;
