import React from 'react';
import './SubmitButton.scss';
import Loader from '../Loader';

interface SubmitButtonProps {
  isLoading: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  isLoading,
  onClick,
  children = "Submit",
  type = "submit",
  disabled = false,
}) => {
  return (
    <button
      disabled={isLoading || disabled}
      style={
        {
          "--button-bg-color": process.env.REACT_APP_ENV_COLOR || "#b3ef40",
        } as React.CSSProperties
      }
      type={type}
      className="submit-button"
      onClick={onClick}
    >
      {isLoading ? <Loader /> : <span>{children}</span>}
    </button>
  );
};

export default SubmitButton;
