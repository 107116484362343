import { axiosRequestInterceptor } from "./api";

export const getReportData = async () => {
	const apiClient = axiosRequestInterceptor();
	const response = await apiClient.post("api/report/analyse");
	return response;
};

export const postMasterTableAgGrid = async (params: any) => {
	const apiClient = axiosRequestInterceptor();
	const response = await apiClient.post("api/report/aggrid", params);
	return response;
};

export const getReportId = async (params: any) => {
	const apiClient = axiosRequestInterceptor();
	const response = await apiClient.post(
		"api/report/analyse-databricks",
		params
	);
	return response;
};
