import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import * as signalR from "@microsoft/signalr";

import "./report-container.scss";

import {
	execSum,
	salesMetricBlocks,
	gpMetricBlocks,
	performanceComparisonPara,
	salesDataPointsTop,
	salesDataPointsBottom,
	gpDataPointsTop,
	gpDataPointsBottom,
	performanceComparisonTable,
	performanceComparisonTableColDefs,
	skuComparisonPara,
	performanceDrivers,
	salesColDefs,
	salesTableRows,
} from "./reportData";

import MasterTable from "./MasterTable/MasterTable";
import ReportTitle from "../../components/ReportTitle";
import ExecSumParagraph from "../../components/ExecSumParagraph";
import { transformRows } from "../../utils/ag-grid-transformers";
import Sources from "../../components/Sources";
import ParentAccordion from "../../components/ParentAccordion";
import { AccordionType } from "../../components/ParentAccordion/ParentAccordion";
import SectionTitle from "../../components/SectionTitle";
import SalesGpSection from "../../components/SalesGpSection/SalesGpSection";
import MetricBlock from "../../components/Sources/MetricBlock";
import ViewDetails from "../../components/ViewDetails";
import DetailsContainer from "../../components/DetailsContainer";
import SummarySentance from "../../components/SummarySentance";
import AgGridStaticTable from "../../components/AgGridStaticTable";
import BulletPoint from "../../components/BulletPoint";
import DriverBlock from "../../components/DriverBlock";
import Button from "../../components/Button";

const backend = process.env.REACT_APP_BE_URL || "";

const Report = () => {
	const [showAnalysis, setShowAnalysis] = useState(false);
	const [showMasterDataTable, setShowMasterDataTable] = useState(false);
	const [showSalesDetails, setShowSalesDetails] = useState(false);
	const [showGpDetails, setShowGpDetails] = useState(false);
	const [
		showPerformanceComparisonDetails,
		setShowPerformanceComparisonDetails,
	] = useState(false);
	const [showSkuComp, setShowSkuComp] = useState(false);

	const [perfCompColDefs, setPerfCompColDefs]: any = useState([]);
	const [salesColDefsState, setSalesColDefs]: any = useState([]);
	const [events, setEvents] = useState<any>([]);
	const [startEvents, setStartEvents] = useState(false);

	const { reportId }: any = useParams();

	useEffect(() => {
		if (!startEvents) return;
		if (!reportId) return;
		const accessToken = localStorage.getItem("accessToken");

		if (!accessToken) {
			console.error("No access token found. Please login first.");
			return;
		}

		// Establish a SignalR connection
		const connection = new signalR.HubConnectionBuilder()
			.withUrl(`${backend}/eventHub?reportId=${reportId}`, {
				accessTokenFactory: () => accessToken,
			}) // Pass DeviceId as a query param
			.withAutomaticReconnect()
			.build();

		connection
			.start()
			.then(() => {
				console.log(`Connected to SignalR for Report ${reportId}`);
			})
			.catch((err) => console.error("SignalR connection error:", err));

		// Listen for new events from the backend
		connection.on("NewEvent", (event) => {
			console.log("Received new event:", event);
			setEvents((prevEvents: any) => [...prevEvents, event]);
		});

		// Cleanup on component unmount
		return () => {
			console.log(`No more events for report ID ${reportId}`);

			connection.stop();
		};
	}, [reportId, startEvents]);

	useEffect(() => {
		console.log("New Event", events);
	}, [events]);

	const agGridMasterApiCallSales = (params: any) => {
		const response = salesColDefs;
		setSalesColDefs(response);
		return salesTableRows;
	};

	const agGridMasterApiCall = (params: any) => {
		const response = performanceComparisonTableColDefs;
		setPerfCompColDefs(response);
		return performanceComparisonTable;
	};

	const getRowStyle = useCallback(
		(params: any) => {
			if (params.node.rowIndex === salesTableRows.length - 1) {
				return {
					backgroundColor: "#E6EBE9",
					fontWeight: "bold",
				};
			}
			return undefined;
		},
		[salesTableRows]
	);

	const startEventsFunc = () => {
		setStartEvents(true);
	};

	return (
		<div className="report">
			{reportId ? (
				<Button
					onClick={() => startEventsFunc()}
					text={"Start Events Webhook"}
					style={{
						backgroundColor: "#e1f0ee",
						opacity: !reportId ? "50%" : "100%",
						pointerEvents: !reportId ? "none" : "",
						marginTop: "24px",
					}}
				/>
			) : (
				<></>
			)}
			<div className="report__header">
				<div className="report__logo" />
				<div className="report__watermark" />
			</div>
			<div className="report__content">
				<ReportTitle text={"Archie Rose Distilling Co"} />
				<div className="report__content-exec-summary">
					{execSum &&
						execSum.map((para: any, index: any) => {
							return (
								<div key={index}>
									<ExecSumParagraph para={para} key={index} />
								</div>
							);
						})}
					<Sources number={6} />
				</div>

				<ParentAccordion
					onClick={() => setShowAnalysis((prev) => !prev)}
					showContent={showAnalysis}
					type={AccordionType.Analytics}
				/>

				{showAnalysis && (
					<div className="report-analysis">
						<SectionTitle title={"Sales and GP$ components"} />
						<div className="report-analysis__sales">
							<div className="report-analysis__sales-content">
								<div className="report-analysis__sales-metrics">
									{salesMetricBlocks &&
										salesMetricBlocks.map((block: any) => {
											const {
												heading,
												subHeading,
												columnWidth,
												variationDirection,
											} = block;
											return (
												<MetricBlock
													heading={heading}
													subHeading={subHeading}
													columnWidth={columnWidth}
													variationDirection={variationDirection}
												/>
											);
										})}
								</div>
								<SalesGpSection
									heading={"Totals sales were $27.7m, down -$1.8m (-6.3%) YOY"}
									bulletPoints={[
										"-$4m impact to sales from a sales unit decrease of -71k to 442k (-13.8% YOY)",
										"+2.2m impact to sales from an ASP increase of +$4.91 to $61.62 (+8.6%). Driven primarily by an RRP increase (mix and discounting effects had a smaller impact",
									]}
								/>
							</div>
							<ViewDetails
								className="view-details"
								onClick={() => setShowSalesDetails((prev) => !prev)}
								showContent={showSalesDetails}
							/>
							{showSalesDetails && (
								<DetailsContainer
									title={"Sales Breakdown"}
									detailsHeading={"Breakdown of changes to sales"}
									detailsSubheading={
										"Archie Rose; Australian Gin; focus v comparison period"
									}
									dataPointsTop={salesDataPointsTop}
									dataPointsBottom={salesDataPointsBottom}
									chart={"sales"}
								/>
							)}
						</div>
						<div
							className="report-analysis__sales"
							style={{ borderTop: "none" }}
						>
							<div className="report-analysis__sales-content">
								<div className="report-analysis__sales-metrics">
									{gpMetricBlocks &&
										gpMetricBlocks.map((block: any, index: number) => {
											const {
												heading,
												subHeading,
												columnWidth,
												variationDirection,
											} = block;
											return (
												<MetricBlock
													key={index}
													heading={heading}
													subHeading={subHeading}
													columnWidth={columnWidth}
													variationDirection={variationDirection}
												/>
											);
										})}
								</div>
								<SalesGpSection
									heading={"GP$ was $4.7m, decreasing -$474k (-9.1%) YOY"}
									bulletPoints={[
										"-$329k impact to GP$ from a sales units decrease.",
										"-$144k impact to GP$ from a GP% decrease of -50 bps to 17.5%. This was caused by an increase in COGS and change in mix (selling more lower margin SKUs). This was also the reason GP$ fell faster than sales.",
									]}
								/>
							</div>
							<ViewDetails
								className="view-details"
								onClick={() => setShowGpDetails((prev) => !prev)}
								showContent={showGpDetails}
							/>
							{showGpDetails && (
								<DetailsContainer
									title={"GP Breakdown"}
									detailsHeading={"Breakdown of changes to GP$"}
									detailsSubheading={
										"Archie Rose; Australian Gin; focus v comparison period"
									}
									dataPointsTop={gpDataPointsTop}
									dataPointsBottom={gpDataPointsBottom}
									chart={"gp"}
								/>
							)}
						</div>
						<SectionTitle title={"Performance Comparison"} />
						<div className="report-analysis__sales">
							{performanceComparisonPara &&
								performanceComparisonPara.map((sentance: string) => {
									return <SummarySentance sentance={sentance} />;
								})}

							<AgGridStaticTable
								// gridRef={gridRef}
								columnDefs={perfCompColDefs}
								// defaultColDef={defaultColDef}
								// gridOptions={gridOptions}
								domLayout={"autoHeight"}
								transformRows={transformRows}
								rowModelType={"serverSide"}
								pagination={true}
								paginationPageSize={20}
								paginationPageSizeSelector={[10, 20, 50, 100]}
								paginateChildRows={true}
								rowGroupPanelShow={"none"}
								groupDisplayType={"multipleColumns"}
								enableCharts={true}
								enableRangeSelection={true}
								serverSideSortAllLevels={true}
								suppressAggFuncInHeader={true}
								agGridMasterApiCall={agGridMasterApiCall}
							/>
							<ViewDetails
								className="view-details"
								onClick={() =>
									setShowPerformanceComparisonDetails((prev) => !prev)
								}
								showContent={showPerformanceComparisonDetails}
							/>
							{showPerformanceComparisonDetails && (
								<SummarySentance sentance={"Performance Comparison Details"} />
							)}
						</div>
						<SectionTitle title={"Breakdown: SKU Comparison"} />
						<div className="report-analysis__sales">
							{skuComparisonPara &&
								skuComparisonPara.map((para: any, index: any) => {
									if (para.sentance) {
										return <SummarySentance sentance={para.sentance} />;
									}
									if (para.bullet) {
										return <BulletPoint bulletPoint={para.bullet} />;
									}
								})}

							<ViewDetails
								className="view-details"
								onClick={() => setShowSkuComp((prev) => !prev)}
								showContent={showPerformanceComparisonDetails}
							/>
							{showSkuComp && (
								<SummarySentance sentance={"SKU Comparison Details"} />
							)}
						</div>
						<SectionTitle title={"Performance Drivers"} />
						<div className="report-analysis__sales drivers">
							{performanceDrivers &&
								performanceDrivers.map((driver: any, index: any) => {
									const { title, blocks } = driver;
									return (
										<div key={index}>
											<SectionTitle
												title={title}
												style={{ marginLeft: "-25px" }}
											/>
											<br />
											<br />
											{blocks.map((block: any, blockIndex: number) => {
												const {
													heading,
													variationDirection,
													paragraph,
													details,
												} = block;
												return (
													<div key={blockIndex}>
														<DriverBlock
															heading={heading}
															paragraph={paragraph}
															variationDirection={variationDirection}
														>
															{details.map((detail: any, index: number) => {
																const { heading, sentance, bullet } = detail;
																return (
																	<div
																		className="driver-block__details"
																		key={index}
																	>
																		<div className="driver-block__heading">
																			{heading}
																		</div>
																		{sentance && (
																			<SummarySentance sentance={sentance} />
																		)}
																		{bullet && (
																			<BulletPoint bulletPoint={bullet} />
																		)}
																	</div>
																);
															})}
															<AgGridStaticTable
																// gridRef={gridRef}
																columnDefs={salesColDefs}
																// defaultColDef={defaultColDef}
																// gridOptions={gridOptions}
																domLayout={"autoHeight"}
																transformRows={transformRows}
																rowModelType={"serverSide"}
																pagination={true}
																paginationPageSize={20}
																paginationPageSizeSelector={[10, 20, 50, 100]}
																paginateChildRows={true}
																rowGroupPanelShow={"none"}
																groupDisplayType={"multipleColumns"}
																enableCharts={true}
																enableRangeSelection={true}
																serverSideSortAllLevels={true}
																suppressAggFuncInHeader={true}
																agGridMasterApiCall={agGridMasterApiCallSales}
																getRowStyle={getRowStyle}
															/>
														</DriverBlock>
													</div>
												);
											})}
										</div>
									);
								})}
						</div>
					</div>
				)}

				<ParentAccordion
					onClick={() => setShowMasterDataTable((prev) => !prev)}
					showContent={showMasterDataTable}
					type={AccordionType.MasterDataTable}
				/>
				{showMasterDataTable && <MasterTable />}
			</div>
		</div>
	);
};

export default Report;
