import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./dropdown-config-container.scss";
import Button from "../../../components/Button";
import Select from "react-select";
import { appInsights } from "../../../services/app-insights.service";
import { postMultiSelectOptions } from "../../../services/dashboard.service";
import { getReportId } from "../../../services/report.service";

import analyseIcon from "../../../assets/icons/pie-chart.svg";
import breakdownIcon from "../../../assets/icons/bar-line-chart.svg";
import calendarIcon from "../../../assets/icons/calendar.svg";
import filterIcon from "../../../assets/icons/filter-lines.svg";
import SelectionSummary from "../../../components/SelectionSummary";

const selectableFields = [
	"Category",
	"Sub-category",
	"Segment",
	"Article",
	"Breakdown",
];

// get currentSelections from fieldsData func
const getCurrentSelections = (fieldsData: any) =>
	fieldsData
		.filter(
			(field: any) =>
				selectableFields.includes(field.name) &&
				field.selected &&
				field.name !== "Breakdown"
		)
		.map((field: any) => {
			return {
				name: field.name,
				selected: field.selected,
			};
		});

// Update fieldOptions state function
const updateFieldOptions = (
	fieldsData: any,
	itemName: string,
	newOptions: any,
	params: any
) => {
	const updatedFields = [...fieldsData];
	const foundItem = updatedFields.find((obj) => obj.name === itemName);

	if (foundItem) {
		// don't append new options when searching and page = 1
		if (params.fields.length === 1 && params.page === 1) {
			foundItem.options = { ...newOptions };
		}
		// append new options otherwise
		foundItem.options = { ...foundItem.options, ...newOptions };
	}
	return updatedFields;
};

const DropdownConfig = (props: any) => {
	const navigate = useNavigate();
	const {
		fieldsData,
		setFieldsData,
		loading,
		showSelectionsSummary,
		setShowSelectionsSummary,
	} = props;

	const [page, setPage] = useState(1);
	const [searchPage, setSearchPage] = useState(1);
	const [itemToFetch, setItemToFetch] = useState<any | null>(null);
	const [searchItem, setSearchItem] = useState<any | null>(null);
	const [searchInput, setSearchInput] = useState("");
	const [showOtherFilters, setShowOtherFilters] = useState(false);
	const [breakdownSelected, setBreakdownSelected] = useState(false);

	useEffect(() => {
		if (!fieldsData) return;
		const anySelected = fieldsData.some((item: any) => {
			return (
				item.category[0] === "other_filters" &&
				item.selected &&
				item.selected !== "All"
			);
		});

		if (anySelected) {
			setShowOtherFilters(true);
		}
	}, [fieldsData]);

	// update fields with new options from endpoint func
	const mergeFieldsData = (fieldsData: any[], data: any[]) => {
		return fieldsData.map((field) => {
			const foundItem = data.find((newField) => newField.name === field.name);

			if (foundItem && foundItem.options) {
				if (foundItem.name === "Breakdown") {
					const firstOptionKey = Object.keys(foundItem.options)[0];

					if (
						field.selected &&
						field.selected !== "Category" &&
						field.selected !== "Sub-category" &&
						field.selected !== "Segment" &&
						field.selected !== "Article"
					) {
						return {
							...field,
							options: { ...foundItem.options },
							selected: field.selected,
						};
					}

					return {
						...field,
						options: { ...foundItem.options },
						selected: firstOptionKey,
						// selected: breakdownSelected ? field.selected : firstOptionKey,
					};
				}

				// Replace the entire options object with the one from the endpoint
				return {
					...field,
					options: { ...foundItem.options },
				};
			}
			return field;
		});
	};

	//handle change on click of dropdown
	const handleChange = (
		index: number,
		selectedOption: any,
		selectedObject: any
	) => {
		if (selectedObject.name === "Breakdown") {
			setBreakdownSelected(true);
		}
		setShowSelectionsSummary(true);

		const updatedFieldsArray: any = fieldsData.map((item: any, i: number) => {
			if (selectedObject === item) {
				// send event to Application Insights
				appInsights.trackEvent({
					name: "dashboardDropdownSelectionEvent",
					properties: {
						dashboardDropdownSelectionEvent: {
							name: item.name,
							category: item.category,
							selected: selectedOption.value,
						},
						user: `${localStorage.getItem("accessToken")}`,
					},
				});
				return {
					...item,
					selected: selectedOption.label, // Save the selected value
				};
			}
			return item;
		});

		const selectedFields = getCurrentSelections(updatedFieldsArray);

		const params = {
			fields: selectableFields,
			page: page,
			pageSize: 53,
			currentSelections: selectedFields,
		};

		loadSearchFields(params)
			.then((response) => {
				const data = response?.data.fields;

				if (data) {
					const updatedFields = mergeFieldsData(updatedFieldsArray, data);
					setFieldsData(updatedFields);
				}
			})
			.catch((error) => console.error("Error updating fields data:", error));
	};

	// API call to grab more fields while search - used in handleChange

	const loadSearchFields = async (params: any) => {
		try {
			const response = await postMultiSelectOptions(params);
			return response;
		} catch (error) {
			console.error("Error fetching search fields:", error);
		}
	};

	// Scroll to load more options useEffect - had to do useEffect to track page number for scroll load

	useEffect(() => {
		if (!itemToFetch) return;

		const loadMoreData = async (item: any) => {
			const currentSelections = getCurrentSelections(fieldsData);

			const params = {
				fields: [item.name],
				search: searchInput,
				page: page,
				pageSize: 54,
				currentSelections: currentSelections,
			};

			try {
				const response = await postMultiSelectOptions(params);
				const data = response.data.fields;
				const updatedFields = updateFieldOptions(
					fieldsData,
					item.name,
					data[0].options,
					params
				);
				setFieldsData(updatedFields);
			} catch (error) {
				console.error("Error fetching dashboard data:", error);
			}
		};

		loadMoreData(itemToFetch);
	}, [itemToFetch, page]);

	//loadMoreOptions is setting item from render to the item selected

	const loadMoreOptions = (item: any) => {
		setItemToFetch(item);
		if (item === null || item !== itemToFetch) {
			setPage(1);
			setSearchPage(1);
		}

		if (item !== searchItem) {
			setSearchInput("");
		}

		setPage((prevPage) => {
			const nextPage = prevPage + 1;
			return nextPage;
		});
	};

	// SEARCH - handle change of text & API call for search functionality

	const handleInputChange = (e: string, item: any) => {
		setSearchPage(1);
		setSearchItem(item);

		if (e === "") return;
		setSearchInput(e);

		if (item === null || item !== searchItem) {
			setSearchPage(1);
			setSearchInput("");
		}

		const currentSelections = getCurrentSelections(fieldsData);

		const params = {
			fields: [item.name],
			search: e,
			page: searchPage,
			pageSize: 53,
			// currentSelections: !item.selected ? currentSelections : [],
			currentSelections: currentSelections,
		};

		loadSearchFields(params)
			.then((response) => {
				const data = response?.data.fields;
				if (data) {
					const updatedFields = updateFieldOptions(
						fieldsData,
						item.name,
						data[0].options,
						params
					);
					setFieldsData(updatedFields);
				}
			})
			.catch((error) => console.error("Error updating fields data:", error));
	};

	// Analyse button click

	const handleAnalyseClick = async () => {
		const cleanedFields = fieldsData.map(({ name, selected }: any) => ({
			name,
			selected,
		}));

		try {
			const data = await getReportId(cleanedFields);
			const reportId = data.data;

			navigate(`/report/${reportId}`);
		} catch (error) {
			console.log("Error retreiving new report ID", error);
		}
	};

	const colorStyles = (selectedValue: string, defaultValue: string | null) => ({
		control: (provided: any) => {
			return {
				...provided,
				backgroundColor: selectedValue
					? selectedValue !== defaultValue
						? process.env.REACT_APP_ENV_COLOR
						: "#FFFFFF" // Use #B3EF40 if selectedValue is different from defaultValue
					: "#FFFFFF",
				border: selectedValue
					? selectedValue !== defaultValue
						? "1px solid rgba(8, 116, 66, 0.32)"
						: "1px solid #E6EBE9" // Use #B3EF40 if selectedValue is different from defaultValue
					: "1px solid #E6EBE9",
			};
		},
	});

	return (
		<>
			{loading ? (
				<div className="analyse">
					<div className="loading-spinner" />
				</div>
			) : (
				<div className="analyse">
					<div className="analyse__container">
						<div className="analyse__breakdown">
							{showSelectionsSummary ? (
								<div className="analyse__summary">
									<div className="analyse__summary-heading">Selection</div>
									<SelectionSummary fieldsData={fieldsData} />
								</div>
							) : (
								<></>
							)}

							{fieldsData &&
								fieldsData.length > 0 &&
								Object.entries(
									fieldsData.reduce((groups: any, item: any) => {
										const category = item.category[0];
										if (!groups[category]) {
											groups[category] = [];
										}
										groups[category].push(item);
										return groups;
									}, {})
								).map(([category, items]: any) => {
									if (category === "other_filters") {
										return (
											<div
												className="analyse__category other-filters"
												key={category}
												style={{
													backgroundColor: "#F6F8F8",
													height: showOtherFilters ? "" : "40px",
												}}
											>
												<div
													className="analyse__category-heading other-filters"
													onClick={() => setShowOtherFilters((prev) => !prev)}
												>
													<>
														<img
															src={filterIcon}
															alt={`analyse-icon`}
															className="analyse__icon"
														/>
														<>Filtered by</>
													</>
												</div>

												<div
													style={{
														display: showOtherFilters ? "block" : "none",
													}}
												>
													{items.map((item: any, index: any) => {
														const {
															options,
															default: defaultValue,
															selected,
														} = item;

														// if (selected && !Object.hasOwn(options, selected)) {
														// 	const nextValue =
														// 		Math.max(
														// 			...(Object.values(options) as number[])
														// 		) + 1;

														// 	(options as Record<string, number>)[selected] =
														// 		nextValue;
														// }

														const formattedOptions = Object.entries(
															options
														).map(([key, value]) => ({
															value: value,
															label: key,
														}));

														const selectedOption = formattedOptions.find(
															(option) =>
																option.label === (selected ?? defaultValue)
														);

														return (
															<div className="analyse__item" key={index}>
																<div className="analyse__title">
																	<div className="analyse__name">
																		{item.name}
																	</div>
																</div>
																<Select
																	key={index}
																	value={selectedOption}
																	onChange={(selectedOption) =>
																		handleChange(index, selectedOption, item)
																	}
																	options={formattedOptions}
																	styles={colorStyles(selected, defaultValue)}
																	onMenuScrollToBottom={
																		formattedOptions.length % 54 == 0
																			? () => loadMoreOptions(item)
																			: () => {}
																	}
																	onInputChange={(e) =>
																		handleInputChange(e, item)
																	}
																/>
															</div>
														);
													})}
												</div>
											</div>
										);
									}

									return (
										<div className="analyse__category" key={category}>
											<div className="analyse__category-heading">
												{category === "product_filter" && (
													<>
														<img
															src={analyseIcon}
															alt={`analyse-icon`}
															className="analyse__icon"
														/>
														<>Analye this</>
													</>
												)}
												{category === "breakdown" && (
													<>
														<img
															src={breakdownIcon}
															alt={`breakdown-icon`}
															className="analyse__icon"
														/>
														<>Breakdown by</>
													</>
												)}
												{category === "time" && (
													<>
														<img
															src={calendarIcon}
															alt={`calendar-icon`}
															className="analyse__icon"
														/>
														<>Compare these periods</>
													</>
												)}
											</div>
											{items.map((item: any, index: any) => {
												const {
													options,
													category,
													name,
													default: defaultValue,
													selected,
												} = item;

												const formattedOptions = Object.entries(options).map(
													([key, value]) => ({
														value: value,
														label: key,
													})
												);

												const selectedOption = formattedOptions.find(
													(option) =>
														option.label === (selected ?? defaultValue)
												);

												return (
													<div className="analyse__item" key={index}>
														<div className="analyse__title">
															<div className="analyse__name">{item.name}</div>
														</div>
														<Select
															key={index}
															value={selectedOption}
															onChange={(selectedOption) =>
																handleChange(index, selectedOption, item)
															}
															options={formattedOptions}
															styles={colorStyles(selected, defaultValue)}
															onMenuScrollToBottom={
																formattedOptions.length % 54 == 0
																	? () => loadMoreOptions(item)
																	: () => {}
															}
															onInputChange={(e) => handleInputChange(e, item)}
														/>
													</div>
												);
											})}
										</div>
									);
								})}
						</div>
					</div>
					<Button
						text={"Analyse"}
						onClick={() => handleAnalyseClick()}
						style={{
							width: "290px",
							marginTop: "18px",
							marginBottom: "24px",
							marginRight: "auto",
							marginLeft: "auto",
							paddingRight: "24px",
							paddingLeft: "24px",
							color: "white",
							borderRadius: "24px",
							// "--button-bg-color": process.env.REACT_APP_ENV_COLOR,
							"--button-bg-color": "#0C937D",
						}}
					/>
				</div>
			)}
		</>
	);
};

export default DropdownConfig;
