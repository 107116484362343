export const transformRows = (data: any) => {
	const { columns, rows } = data;

	if (!data || !data.rows || data.rows.length === 0) {
		return { rows: [], lastRow: null };
	}

	let lastRowIndex = 0;

	const createRowObjects = rows.map((row: any, index: number) => {
		lastRowIndex = index;
		// return columns.reduce((obj: any, column: any, index: any) => {
		//   obj[column.name] = row[index];
		//   return obj;
		return columns.reduce((obj: any, column: any, index: any) => {
			obj[column.name] =
				row[index] !== undefined && row[index] !== null
					? Number(row[index])
					: null;
			return obj;
		}, {});
	});

	return {
		rows: createRowObjects,
		lastRow: lastRowIndex + 1,
	};
};
