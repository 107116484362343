import React, { useEffect, useState, useRef } from "react";

import "./chat-input-container.scss";
import { FaArrowCircleUp } from "react-icons/fa";
import ExpandingTextarea from "../../../components/ExpandingTextarea";
import { appInsights } from "../../../services/app-insights.service";
import ExpandableText from "../../../components/ExpandableText";
import {
	postMultiSelect,
	postMultiSelectOptions,
} from "../../../services/dashboard.service";

import { exampleQuestions } from "../../../routes/Report/reportData";

import gwLogoBlack from "../../../assets/logo/goodwork-ai_logo_black.svg";
import newChat from "../../../assets/icons/edit.svg";
import userIcon from "../../../assets/icons/user-circle.svg";
import refreshIcon from "../../../assets/icons/refresh.svg";
import ExampleQuestionContainer from "../../../components/ExampleQuestionContainer";
import TypewriterAnimation from "../../../components/TypewriterAnimation";

const ChatInput = (props: any) => {
	const {
		dropdownFieldsParent,
		setFieldsData,
		getFieldsInitialState,
		loading,
		setLoading,
		chatGPTModel,
		setChatGPTModel,
		defaultWeek,
		setShowSelectionsSummary,
	} = props;
	const [inputText, setInputText] = useState("");
	const [conversation, setConversation]: any = useState([]);
	const [historyArray, setHistoryArray] = useState([]);
	const [isFirstMessage, setIsFirstMessage] = useState(true);
	const [visibleIndex, setVisibleIndex] = useState(0);
	const itemsPerPage = 3;

	const endOfMessagesRef = useRef<HTMLDivElement>(null);

	// Build historyArray to insert into new userMessage

	useEffect(() => {
		let tempHistoryArray: any = [];
		let messageContent: object;

		if (conversation.length <= 0) return;
		// const lastMessage = conversation[conversation.length - 1];

		// if (lastMessage.dashboard) {
		conversation.forEach((message: any) => {
			if (message.openai) {
				messageContent = message.openai.instruction;
			} else if (message.aiText) {
				messageContent = {
					role: "assistant",
					content: message.aiText?.aiText,
				};
			}
			tempHistoryArray.push(messageContent);
		});
		// } else return;

		setHistoryArray(tempHistoryArray);
	}, [conversation]);

	// Message Submit

	const onMessageSubmit = async (text: string) => {
		const finalInputText = text ?? inputText;
		if (finalInputText.trim() === "") return;
		setIsFirstMessage(false);
		setShowSelectionsSummary(true);

		try {
			// Create a new user message object
			const newUserMessage = {
				openai: {
					history: historyArray,
					model: chatGPTModel,
					instruction: { role: "user", content: finalInputText },
				},
				state: {
					fields: [], // Placeholder for any specific state fields, if needed
				},
			};

			// Filter and format payload state from dropdownFieldsParent
			const payloadState = dropdownFieldsParent.map(
				({ name, category, selected, default: defaultValue }: any) => ({
					name,
					category,
					selected: selected ?? defaultValue,
				})
			);

			// Add the new user message to the conversation array
			setConversation((prevConversation: any[]) => [
				...prevConversation,
				newUserMessage,
			]);

			// Define the request params
			const params = {
				openai: {
					history: historyArray,
					instruction: {
						role: "user",
						content: finalInputText,
					},
					model: chatGPTModel,
				},
				state: payloadState,
				currenttime: {
					week: defaultWeek,
					year: 2023,
				},
			};

			// Track user message event
			appInsights.trackEvent({
				name: "userMessage",
				properties: {
					messagePayload: params,
					user: `${localStorage.getItem("email")}`,
				},
			});

			// Reset input and show loading
			setInputText("");
			setLoading(true);

			// Make the API call to postMultiSelect
			const result = await postMultiSelect(params);

			// Update conversation with API response
			setConversation((prevConversation: any[]) => [
				...prevConversation,
				result.data,
			]);

			// Update the dropdown fields based on the API response
			const updatedFields = dropdownFieldsParent.map((field: any) => {
				const matchingUpdate = result.data.updates.find(
					(update: any) => update.name === field.name
				);
				return {
					...field,
					selected: matchingUpdate ? matchingUpdate.selected : "All",
				};
			});

			// console.log(updatedFields);

			let optionsParams: {
				fields: string[];
				page: number;
				pageSize: number;
				currentSelections: { name: string; selected: string }[];
			};
			const currentSelections: { name: string; selected: string }[] = [];

			updatedFields.forEach(
				(field: { name: string; selected: string; default: string }) => {
					if (
						field.name !== "Category" &&
						field.name !== "Sub-category" &&
						field.name !== "Segment" &&
						field.name !== "Article"
					)
						return;

					if (field.selected !== field.default) {
						currentSelections.push({
							name: field.name,
							selected: field.selected,
						});
					}
				}
			);

			optionsParams = {
				fields: ["Category", "Sub-category", "Segment", "Article", "Breakdown"],
				page: 1,
				pageSize: 53,
				currentSelections,
			};

			try {
				const newOptions = await postMultiSelectOptions(optionsParams);

				// rename updatedFields below as we need to look through updated fields above

				setFieldsData((prevFields: any) => {
					const updatedFieldsOptions = updatedFields.map((field: any) => {
						const matchingField = newOptions.data.fields.find(
							(update: any) => update.name === field.name
						);
						return {
							...field,
							options: matchingField ? matchingField.options : field.options,
						};
					});

					// Compare new and old fields to avoid unnecessary re-renders
					const isEqual =
						JSON.stringify(prevFields) === JSON.stringify(updatedFieldsOptions);
					return isEqual ? prevFields : updatedFieldsOptions;
				});
				setLoading(false);
			} catch (error) {
				console.log(error);
				setLoading(false);
			}

			// Track API response event
			const tempResponse = { ...result.data }; // Create a copy of the result data
			appInsights.trackEvent({
				name: "chatApiResponse",
				properties: {
					apiChatResponsePayload: tempResponse,
					user: `${localStorage.getItem("email")}`,
				},
			});
		} catch (error) {
			console.error("Error during message submission:", error);
			setLoading(false); // Ensure loading is stopped even in case of an error
		}
	};

	// Autoscroll to bottom of the chat window

	useEffect(() => {
		if (endOfMessagesRef.current) {
			endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [conversation]);

	// Enter to submit message

	const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (event.key === "Enter") {
			if (event.shiftKey) {
				return;
			} else event.preventDefault();
			onMessageSubmit(inputText);
		}
	};

	const handleNewChatClick = () => {
		getFieldsInitialState();
		setConversation([]);
		setShowSelectionsSummary(false);
	};

	const chatGPTModels = [
		{ name: "ChatGPT 4o", value: "gpt4o" },
		{ name: "ChatGPT 3", value: "gpt3" },
	];
	const defaultModelIndex = 0;

	const handleNextExamples = () => {
		setVisibleIndex((prevIndex) =>
			prevIndex + itemsPerPage >= exampleQuestions.length
				? 0 // Reset to the start if we've reached the end
				: prevIndex + itemsPerPage
		);
	};

	const onExampleClick = (text: string) => {
		setInputText(text);
		onMessageSubmit(text);
	};

	return (
		<div className="chat">
			<div className="chat__header">
				<div className="chat__button" onClick={() => handleNewChatClick()}>
					<img
						src={newChat}
						alt={"new chat icon"}
						className="chat__button-icon"
					/>
					New Chat
				</div>
				{/* <div className="chat__radio-buttons" style={{ marginRight: "1.5rem" }}>
					{chatGPTModels.map((item: any, index: number) => {
						return (
							<React.Fragment key={item.value}>
								<input
									type="radio"
									id={item.value}
									name="gpt_model"
									style={{ marginLeft: "0.8rem" }}
									onClick={() => setChatGPTModel(item.value)}
									defaultChecked={index == defaultModelIndex}
								/>
								<label>{item.name}</label>
							</React.Fragment>
						);
					})}
				</div> */}
				<img src={gwLogoBlack} alt={"Goodwork Logo"} className="chat__logo" />
			</div>
			<div className={`chat__window ${isFirstMessage ? "centered" : ""}`}>
				{conversation &&
					conversation.length > 0 &&
					conversation.map((message: any, index: number) => {
						if (message.openai) {
							return (
								<div className="chat__user-message" key={index}>
									{message.openai.instruction.content
										?.split("\n")
										.map((line: any, i: any) => (
											<span key={i}>
												{line}
												<br />
											</span>
										))}
									<img src={userIcon} className="chat__user-icon" />
								</div>
							);
						} else {
							return (
								<div className="chat__response-message" key={index}>
									<div
										className="chat__response-logo"
										style={{ whiteSpace: "pre-line" }}
									/>
									<div className="chat__response-text">
										{message.aiText.userText ? (
											<>
												<TypewriterAnimation
													message={message.aiText.userText}
													speed={20}
												/>
												<ExpandableText
													text={message.aiText.aiReasoning}
													maxLength={0}
												/>
											</>
										) : (
											"Loading..."
										)}
									</div>
								</div>
							);
						}
					})}
				<div ref={endOfMessagesRef} />
			</div>
			<div className={`chat__textarea ${isFirstMessage ? "centered" : ""}`}>
				{isFirstMessage && (
					<div className="chat__heading">What do you want to analyse?</div>
				)}
				<ExpandingTextarea
					value={inputText}
					onChange={setInputText}
					placeholder="Don't be shy, ask me a question!"
					style={{
						marginLeft: "24px",
						marginRight: "24px",
						paddingRight: "50px",
						lineHeight: "16px",
						overflow: isFirstMessage ? "visible" : "hidden",
					}}
					rows={isFirstMessage ? 6 : 3}
					disabled={loading}
					maxLength={1000}
					onKeyDown={handleKeyDown}
				>
					<FaArrowCircleUp
						className={`chat__submit ${isFirstMessage ? "centered" : ""}`}
						onClick={() => onMessageSubmit(inputText)}
						style={{
							cursor: loading ? "not-allowed" : "pointer", // Disable pointer interaction
							color: loading ? "grey" : "#0D1005", // Change color to grey when loading
						}}
					/>
				</ExpandingTextarea>
				{isFirstMessage && (
					<div className="chat__suggest-cont">
						<div className="chat__sugg-cont-header">
							<div className="chat__examples">Examples: </div>
							<div className="chat__idea" onClick={() => handleNextExamples()}>
								<img
									src={refreshIcon}
									alt={"refresh icon"}
									className="chat__refresh-icon"
								/>
								Regenerate Ideas
							</div>
						</div>
						<div className="chat__examples-container">
							{exampleQuestions
								.slice(visibleIndex, visibleIndex + itemsPerPage) // Render the current slice
								.map((question: string, index: number) => (
									<ExampleQuestionContainer
										text={question}
										onClick={onExampleClick}
										key={index}
									/>
								))}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default ChatInput;
