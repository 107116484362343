import React from "react";
import "./section-title-container.scss";

const SectionTitle = ({ title, style }: any) => {
	return (
		<div className="section-title" style={style}>
			{title}
		</div>
	);
};

export default SectionTitle;
