import React, { useEffect, useRef } from "react";
import "./waterfall-chart-component.scss";
// @ts-ignore
import CanvasJSReact from "@canvasjs/react-charts";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

let stripLines: any;

const WaterfallChart = ({
	handleWaterfallBarClick,
	dataPoints,
	chart,
}: any) => {
	// Format the data points to include conditional index labels

	let formattedDataPoints: any;

	formattedDataPoints = dataPoints.map((point: any, index: number) => {
		const isGap = [1, 2].includes(index);

		return {
			...point,
			indexLabel:
				(point.y >= 0
					? `+$${(point.y / 1000000.0).toFixed(1)}`
					: `-$${(Math.abs(point.y) / 1000000.0).toFixed(1)}`) + "m",
			indexLabelFontColor: "#fff",
		};
	});

	if (chart === "salesTop") {
		stripLines = [
			{
				startValue: -0.5,
				endValue: 0.5,
				color: "#F9FBFA",
			},
			{
				startValue: 0.5,
				endValue: 1.5,
				color: "#FDFEF0",
			},
			{
				startValue: 1.5,
				endValue: 2.5,
				color: "#F0F9FE",
			},
			{
				startValue: 2.5,
				endValue: 3.5,
				color: "#F9FBFA",
			},
		];
	}
	if (chart === "salesBottom") {
		stripLines = [
			{
				startValue: -0.5,
				endValue: 0.5,
				color: "#F9FBFA",
			},
			{
				startValue: 0.5,
				endValue: 1.5,
				color: "#FDFEF0",
			},
			{
				startValue: 1.5,
				endValue: 2.5,
				color: "#FDFEF0",
			},
			{
				startValue: 2.5,
				endValue: 3.5,
				color: "#F0F9FE",
			},
			{
				startValue: 3.5,
				endValue: 4.5,
				color: "#F9FBFA",
			},
		];
	}

	if (chart === "gpTop") {
		stripLines = [
			{
				startValue: -0.5,
				endValue: 0.5,
				color: "#F9FBFA",
			},
			{
				startValue: 0.5,
				endValue: 1.5,
				color: "#FDFEF0",
			},
			{
				startValue: 1.5,
				endValue: 2.5,
				color: "#F0F9FE",
			},
			{
				startValue: 2.5,
				endValue: 3.5,
				color: "#F9FBFA",
			},
		];
	}
	if (chart === "gpBottom") {
		stripLines = [
			{
				startValue: -0.5,
				endValue: 0.5,
				color: "#F9FBFA",
			},
			{
				startValue: 0.5,
				endValue: 1.5,
				color: "#FDFEF0",
			},
			{
				startValue: 1.5,
				endValue: 2.5,
				color: "#F0F9FE",
			},
			{
				startValue: 2.5,
				endValue: 3.5,
				color: "#F0F9FE",
			},
			{
				startValue: 3.5,
				endValue: 4.5,
				color: "#F0F9FE",
			},

			{
				startValue: 4.5,
				endValue: 5.5,
				color: "#F9FBFA",
			},
		];
	}

	const handleBarClick = (event: any) => {
		const { dataPoint } = event;
		// alert(`You clicked on value: ${dataPoint.y}`);
		handleWaterfallBarClick();
	};

	const options = {
		theme: "light2",
		title: {
			// text: "GoodWork Waterfall Chart"
		},
		animationEnabled: true,
		axisX: {
			interval: 1,
			labelFontSize: 0,
			labelFontColor: "#000",
			stripLines: stripLines,
		},
		axisY: {
			valueFormatString: "$#,###,##0.",
			gridThickness: 0, // Removes horizontal grid lines
		},
		data: [
			{
				type: "waterfall",
				click: handleBarClick, // Assign the click handler
				yValueFormatString: "$#,##0,,.0m",
				yValueFontColor: "#FF5733", // Sets the color of the Y-axis tick labels
				indexLabel: "{y}",
				indexLabelColor: "red",
				indexLabelPlacement: "inside",
				risingColor: "#0D8730",
				fallingColor: "#B73125",
				dataPoints: formattedDataPoints,
			},
		],
	};

	const chartContainer: any = useRef(null);

	// useEffect(() => {
	//   chartContainer.current?.render();
	// }, []);

	useEffect(() => {
		// Adjust the headers' width dynamically based on the chart's width
		const chartWidth = chartContainer.current?.clientWidth;
		const headers = document.querySelectorAll(".chart-header");
		if (chartWidth && headers.length) {
			const sectionWidth = chartWidth / options.data[0].dataPoints.length;
			headers.forEach((header, index) => {
				(header as HTMLElement).style.width = `${sectionWidth}px`;
				(header as HTMLElement).style.left = `${index * sectionWidth}px`;
			});
		}
	}, []);

	return (
		<div className="waterfall">
			<div className={`x-axis-labels ${chart}`}>
				<>
					{dataPoints.map((a: any, index: number) => {
						return (
							<span
								key={index}
								dangerouslySetInnerHTML={{ __html: a.label }}
								style={{
									width: `calc(100% / ${dataPoints.length})`,
									backgroundColor: stripLines[index % stripLines.length].color,
								}}
							></span>
						);
					})}
				</>
			</div>
			<CanvasJSChart options={options} ref={chartContainer} />
			{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
		</div>
	);
};

export default WaterfallChart;
