import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { forgotPassword } from "../../services/auth.service";
import SuccessAlert from "../../components/SuccessAlert";
import SubmitButton from "../../components/SubmitButton";
import "./ForgotPassword.scss";
import Logo from "../../components/Logo";

const ForgotPassword = () => {
	const navigate = useNavigate();

	const [emailAddress, setEmailAddress] = useState<string>("");
	const [error, setError] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [successMessage, setSuccessMessage] = useState<string>("");

	async function handleForgotPassword(emailAddress: string) {
		try {
			setError("");
			setIsLoading(true);
			const response = await forgotPassword(emailAddress);
			if (response.status === 200) {
				setSuccessMessage(
					"An email has been sent, please follow the link to reset your password."
				);
			}
			setIsLoading(false);
		} catch (error: any) {
			setError(error.response.data);
			setIsLoading(false);
		}
	}

	const handleEmailChange = (e: any) => {
		const email = e.target.value;
		setEmailAddress(email);
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		if (emailAddress == undefined) return;

		await handleForgotPassword(emailAddress);
	};

	return (
		<div className="forgot-password-container">
			<Logo />
			{successMessage ? (
				<SuccessAlert message={successMessage}></SuccessAlert>
			) : (
				<form onSubmit={handleSubmit}>
					<div className="form-group">
						<input
							type="email"
							id="email"
							className="form-input"
							placeholder=" "
							onChange={(e) => handleEmailChange(e)}
						/>
						<label className="form-label">Email Address</label>
						<p className="error">{error}</p>
					</div>

					<SubmitButton isLoading={isLoading} onClick={() => handleSubmit}>
						Send Reset Link
					</SubmitButton>
				</form>
			)}
			<Link className="forgot-password" to="/login">
				Back to login
			</Link>
		</div>
	);
};

export default ForgotPassword;
