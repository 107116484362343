import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";

import "./admin-container.scss";
import { getAllUsers, registerUser, switchUser } from "../../services/user.service";
import Popup from "../../components/Popup";
import { getAllOrganisations } from "../../services/organisation.service";

const Admin = () => {
	const [users, setUsers]: any[] = useState();
	const [trigger, setTrigger] = useState(0);

	useEffect(() => {
		const fetchSelectOptions = async () => {
			try {
				getAllOrganisations().then(res => {
					let options = res.data.map((x: any) => ({ label: x.name, value: x.id }));

					setFormFields((prevFields) =>
						prevFields.map((field) =>
							field.name === "organisationId"
								? { ...field, options: options, value: options.length == 1 ? options[0].value : "" }
								: field
						)
					);
				})


			} catch (error) {
				console.error("Error fetching select options:", error);
			}
		};

		fetchSelectOptions();
	}, []); // Run this effect once when the component mounts

	useEffect(() => {
		getAllUsers().then((res: any) => {
			let sorted = res.data.sort((a: any, b: any) => {
				const orgComparison = a.organisationName?.localeCompare(b.organisationName);
				if (orgComparison === 0) {
					return a.firstName.localeCompare(b.firstName);
				}
				return orgComparison;
			});
			setUsers(sorted);
		});

		return () => {
		};
	}, [trigger]);

	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [formFields, setFormFields] = useState([
		{ id: 1, name: "firstName", label: "First Name", type: "text", value: "", required: true },
		{ id: 2, name: "lastName", label: "Last Name", type: "text", value: "", required: true },
		{ id: 3, name: "username", label: "Email", value: "", type: "text", required: true },
		{ id: 4, name: "organisationId", label: "Organisation", value: "", type: "select", options: [], required: true },
		{ id: 5, name: "role", label: "Role", value: "User", type: "select", options: [{ label: "User", value: "User" }, { label: "Admin", value: "Admin" }], required: true },
	]);

	const handleInputChange = (id: any, value: any) => {
		setFormFields((prevFields) =>
			prevFields.map((field) =>
				field.id === id ? { ...field, value } : field
			)
		);
	};

	const handleOnClose = () => {
		setFormFields((prevFields) =>
			prevFields.map((field) => ({ ...field, value: "" }))
		);
		setIsPopupOpen(false);
	};

	const handleSubmit = async () => {
		setLoading(true);

		const missingRequiredFields = formFields
			.filter((field) => field.required && !field.value.trim())
			.map((field) => field.label);

		if (missingRequiredFields.length > 0) {
			alert(
				`Please fill in the following required fields: ${missingRequiredFields.join(
					", "
				)}`
			);
			setLoading(false);
			return;
		}

		const data = formFields.reduce(
			(acc, field) => ({ ...acc, [field.name]: field.value }),
			{}
		);

		try {
			registerUser(data).then(
				res => {
					setIsPopupOpen(false); // Close the pop-up
					setFormFields((prevFields) =>
						prevFields.map((field) => ({ ...field, value: "" }))
					);
					setTrigger((prev) => prev + 1);
					setLoading(false);
				},
				err => {
					alert("Failed to submit form. Please try again.");
					setLoading(false);
				}
			);
		} catch (error) {
			console.error("Error submitting form:", error);
			alert("An error occurred. Please try again.");
		}
	};

	const handleSwitchUser = async (user: any) => {
		user.active = null;
		switchUser(user.id).then(res => {
			setTrigger((prev) => prev + 1);
		})
	}

	return (
		<div>
			<div className="actions">
				<button className="add-btn" onClick={() => setIsPopupOpen(true)}>
					Add
				</button>
			</div>
			<Popup
				isOpen={isPopupOpen}
				onClose={handleOnClose}
				formFields={formFields}
				onInputChange={handleInputChange}
				onSubmit={handleSubmit}
				loading={loading}
			/>
			{
				users != null ? (
					<table className="styled-table">
						<thead>
							<tr>
								<th>Organisation</th>
								<th>First Name</th>
								<th>Last Name</th>
								<th>Email</th>
								<th>Role</th>
								{/* <th>Phone Number</th> */}
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{users.map((item: any) => (
								<tr key={item.id}>
									<td>{item.organisationName}</td>
									<td>{item.firstName}</td>
									<td>{item.lastName}</td>
									<td>{item.email}</td>
									<td>{item.role}</td>
									{/* <td>{item.phoneNumber}</td> */}
									<td>
										{/* <button className="edit-btn">Edit</button> */}
										{
											item.role == 'SuperAdmin' ? ''
												:

												item.active == null ? ""
													: item.active ? (
														<button className="disable-btn" onClick={() => handleSwitchUser(item)}>Disable</button>
													) : (
														<button className="enable-btn" onClick={() => handleSwitchUser(item)}>Enable</button>
													)}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				) : (
					""
				)
			}
		</div>
	);
};

export default Admin;
