export const execSum = [
	{
		title: "Overall Performance",
		sentances: [
			{ sentance: "Sales were $27.2m. They decreased -$1.8m (-6.3%)." },
			{ sentance: "GP$ was $4.7m. It decreased -$474k (-9.1%)." },
			{
				sentance:
					"GP% decreased -50 bps to 17.5%. This was primarily from a COGS increase and a sales mix change with sales shifting to less profitable SKUs.",
			},
		],
	},
	{
		title: "Performance Comparison",
		sentances: [
			{
				sentance:
					"Archie Rose performed worse than the Australian Gin Segment, Gin Sub-Category and Spirits Category. Its underperformance meant its contribution to Segment sales dropped from 51.3% last year to 39.4% this year.",
			},
		],
	},
	{
		title: "Performance Drivers",
		sentances: [
			{
				sentance:
					"The main reason Archie Rose underperformed was because its promotional sales decreased -$2.8m. Its products were promoted less frequently and at a lower discount % than the previous time period.",
			},
			{
				sentance:
					"Two of its six SKUs in particular saw lower promotional sales. These were:",
			},
			{ bullet: "Signature Dry Gin 700ml (promo sales = -$1.4m; -16%)" },
			{ bullet: "Bone Dry Gin 700ml (promo sales = -$0.6m; -100%)." },
		],
	},
];

export const salesSummary = {
	heading: "Totals sales were $27.7m, down -$1.8m (-6.3%) YOY",
	bulletPoints: [
		"-$4m impact to sales from a sales unit decrease of -71k to 442k (-13.8% YOY)",
		"+2.2m impact to sales from an ASP increase of +$4.91 to $61.62 (+8.6%). Driven primarily by an RRP increase (mix and discounting effects had a smaller impact",
	],
};

export const salesMetricBlocks = [
	{
		heading: "Total Sales $27.2m",
		subHeading: "-$1.8m (-6.3%) YOY",
		columnWidth: 2,
		variationDirection: "-",
	},
	{
		heading: "Units 442k",
		subHeading: "-71k (-16%) YOY",
		columnWidth: 1,
		variationDirection: "-",
	},
	{
		heading: "ASP $61.62",
		subHeading: "+$4.91 (+8.6%) YOY",
		columnWidth: 1,
		variationDirection: "+",
	},
];
export const gpMetricBlocks = [
	{
		heading: "Total GP $4.7m",
		subHeading: "-$474k (-9.1%) YOY",
		columnWidth: 2,
		variationDirection: "-",
	},
	{
		heading: "COGS $50.85",
		subHeading: "+$4.35 (+9.4%) YOY",
		columnWidth: 1,
		variationDirection: "-",
	},
	{
		heading: "% Margin 17.5%",
		subHeading: "-50 bps YOY",
		columnWidth: 1,
		variationDirection: "-",
	},
];

export const salesTableRows = [
	{
		sku: "Signature Dry Gin 700ml",
		salesUnits: "249,416",
		salesUnitsChange: "-29,986",
		"salesUnits%Change": "-11.20%",
		sales: "$14,874,603",
		"sales%Change": "-4.20%",
		GP$: "$2,496,498",
		"GP%Change": "-10.10%",
		"GP%PerLitre": "17.50%",
		"GP%PerLitreChange": "-1.50%",
		ASPPerLitre: "$59.64",
		"ASP%ChangePerLitre": "8.20%",
	},
	{
		sku: "Straight Dry Gin 700ml",
		salesUnits: "135,816",
		salesUnitsChange: "366",
		"salesUnits%Change": "0%",
		sales: "$8,693,619",
		"sales%Change": "-8.60%",
		GP$: "$1,620,345",
		"GP%Change": "3.50%",
		"GP%PerLitre": "19.30%",
		"GP%PerLitreChange": "-1.70%",
		ASPPerLitre: "$64.01",
		"ASP%ChangePerLitre": "8.50%",
	},
	{
		sku: "Bone Dry Gin 700ml",
		salesUnits: "135,816",
		salesUnitsChange: "366",
		"salesUnits%Change": "0%",
		sales: "$8,693,619",
		"sales%Change": "-8.60%",
		GP$: "$1,620,345",
		"GP%Change": "3.50%",
		"GP%PerLitre": "19.30%",
		"GP%PerLitreChange": "-1.70%",
		ASPPerLitre: "$64.01",
		"ASP%ChangePerLitre": "8.50%",
	},
	{
		sku: "Tailored Gin 700ml",
		salesUnits: "135,816",
		salesUnitsChange: "366",
		"salesUnits%Change": "0%",
		sales: "$8,693,619",
		"sales%Change": "-8.60%",
		GP$: "$1,620,345",
		"GP%Change": "3.50%",
		"GP%PerLitre": "19.30%",
		"GP%PerLitreChange": "-1.70%",
		ASPPerLitre: "$64.01",
		"ASP%ChangePerLitre": "8.50%",
	},
	{
		sku: "Raw Honey Gin 700ml",
		salesUnits: "135,816",
		salesUnitsChange: "366",
		"salesUnits%Change": "0%",
		sales: "$8,693,619",
		"sales%Change": "-8.60%",
		GP$: "$1,620,345",
		"GP%Change": "3.50%",
		"GP%PerLitre": "19.30%",
		"GP%PerLitreChange": "-1.70%",
		ASPPerLitre: "$64.01",
		"ASP%ChangePerLitre": "8.50%",
	},
	{
		sku: "Distillers Strength Gin 700ml",
		salesUnits: "135,816",
		salesUnitsChange: "366",
		"salesUnits%Change": "0%",
		sales: "$8,693,619",
		"sales%Change": "-8.60%",
		GP$: "$1,620,345",
		"GP%Change": "3.50%",
		"GP%PerLitre": "19.30%",
		"GP%PerLitreChange": "-1.70%",
		ASPPerLitre: "$64.01",
		"ASP%ChangePerLitre": "8.50%",
	},
	{
		sku: "Total",
		salesUnits: "442,179",
		salesUnitsChange: "-70,517",
		"salesUnits%Change": "-14.20%",
		sales: "$27,247,828",
		"sales%Change": "-6.10%",
		GP$: "$4,762,293",
		"GP%Change": "-9.80%",
		"GP%PerLitre": "17.10%",
		"GP%PerLitreChange": "-1.40%",
		ASPPerLitre: "$61.62",
		"ASP%ChangePerLitre": "9.70%",
	},
];

export const salesColDefs = [
	{ field: "sku" },
	{
		headerName: "Sales Units",
		children: [
			{ field: "salesUnits" },
			{ field: "salesUnitsChange" },
			{ field: "salesUnits%Change" },
		],
	},
	{
		headerName: "Other Measures",
		children: [
			{ field: "sales" },
			{ field: "sales%Change" },
			{ field: "GP$" },
			{ field: "GP%Change" },
			{ field: "GP%PerLitre" },
			{ field: "GP%PerLitreChange" },
			{ field: "ASPPerLitre" },
			{ field: "ASP%ChangePerLitre" },
		],
	},
];

export const performanceComparisonPara = [
	"Archie Rose was the biggest supplier in the Australian Gin Segment for both sales and GP$.",
	"But it underperformed the Segment YOY so its contribution to Segment sales and GP$ decreased.",
	"Its sales made up 39.4% of the Segment, down -11.9 ppt from last year. Its GP$ made up 36.4% of Segment GP$, down -5.6 ppt from last year.",
	"It also underperformed the Gin Sub-Category and the Spirits Category. Comparative underperformance is below:",
];
export const skuComparisonPara = [
	{
		sentance:
			"Archie Rose's underperformance was mainly caused by two of its six SKUs: Signature Dry Gin 700ml and Bone Dry Gin 700ml.",
	},
	{
		bullet:
			"Signature Dry Gin 700ml: sales were $14.9m, decreasing -$0.60m (-4.2%), and GP$ was $2.5m, decreasing -$0.28m (-10.1%).",
	},
	{
		bullet:
			"Bone Dry Gin 700ml: sales were $3.1m, decreasing -$1.3m (-29.5%) YOY, and GP$ was $0.45m, decreasing -$0.12m (-20.5%) YOY.",
	},
	{
		sentance:
			"Both SKUs decreased mainly because they sold less on promotion. They were promoted less frequently and at a lower discount % than last year. See promotions analysis below.  ",
	},
	{
		sentance:
			"Straight Dry Gin was the only SKU saw positive sales and GP growth. This was mainly because of increased ranging across the store network. See analysis below.",
	},
	{
		bullet:
			"Straight Dry Gin 700ml: sales were $8.7m, increasing +0.69m (+8.6%) YOY. GP$ were $1.6m, increasing +0.05m (+3.5%). ",
	},
];

export const performanceComparisonTable = [
	{
		breakdown: "Archie Rose",
		sales: "$27.20",
		salesChange: "-6%",
		tradeGp: "$4.70",
		tradeGpChange: "-9%",
		"gp%": "17.50%",
		"change(bps)": "-50",
		"unitSales(m)": "0.4",
		unitSalesChange: "-14%",
	},
	{
		breakdown: "Rest of Australian Gin Segment",
		sales: "$41.90",
		salesChange: "5%",
		tradeGp: "$8.20",
		tradeGpChange: "8%",
		"gp%": "19.60%",
		"change(bps)": "54",
		"unitSales(m)": "0.7",
		unitSalesChange: "-3%",
	},
	{
		breakdown: "Gin Sub-Category",
		sales: "$92.80",
		salesChange: "0%",
		tradeGp: "$18.50",
		tradeGpChange: "3%",
		"gp%": "19.90%",
		"change(bps)": "58",
		"unitSales(m)": "1.4",
		unitSalesChange: "-7%",
	},
	{
		breakdown: "Spirits Category",
		sales: "$513",
		salesChange: "-1%",
		tradeGp: "$134",
		tradeGpChange: "0%",
		"gp%": "26.10%",
		"change(bps)": "20",
		"unitSales(m)": "8.8",
		unitSalesChange: "-6%",
	},
];

export const performanceComparisonTableColDefs = [
	{ field: "breakdown" },
	{ field: "sales" },
	{ field: "salesChange" },
	{ field: "tradeGp" },
	{ field: "tradeGpChange" },
	{ field: "gp%" },
	{ field: "change(bps)" },
	{ field: "unitSales(m)" },
	{ field: "unitSalesChange" },
];

export const performanceDrivers = [
	{
		title: "Product and Store",
		blocks: [
			{
				heading: "Promotions",
				variationDirection: "-",
				paragraph:
					"Promotional sales fell -$2.8m (-20%). Promotional GP$ fell -$629k (-23%)",
				details: [
					{ heading: "Deep Dive: Promotions" },
					{
						sentance:
							"Promo sales had the largest negative impact on Archie Rose's performance.",
					},
					{
						bullet:
							"Promotional sales decreased -$2.8m (-20%), with its share of total sales decreasing -7pp.",
					},
					{
						bullet:
							"Promotional sales decreased -$2.8m (-20%), with its share of total sales decreasing -7pp.",
					},
					{
						sentance:
							"The frequency of promotions decreased (-1.1 week) and the discount level decreased (-342bps) between the time periods. The biggest impact was on Signature Dry Gin 700ml (sales = $7.2m; -16%) which accounted for 50.3% of the change. The tables below detail these changes.",
					},
					{
						sentance: "The table below detail these changes",
					},
				],
			},
			{
				heading: "New/Deleted SKUs",
				paragraph: "Minor positive changes",
				variationDirection: null,
				details: [],
			},
			{
				heading: "Store Ranging",
				variationDirection: "+",
				paragraph:
					"Increased store network availability contributed +$316,403 in sales, and $47,590 in GP$, to Archie Rose's performance.",
				details: [
					{ heading: "Deep Dive: Store Ranging" },
					{
						sentance:
							"Increased stocking of Archie Rose's products across the store network contributed +$316k to its sales. Like-for-like sales decreased -$2.4m.",
					},
					{
						sentance: "The majority of this impact was in two SKUs:",
					},
					{
						bullet:
							"Straight Dry Gin 700ml: increased stocking contributed +$418k to its sales. It was ranged stocked in +129 more stores YOY.",
					},
					{
						bullet:
							"Tailored Gin 200ml: increased stocking contributed +$119k to its sales. It was ranged in +20 more stores YOY.",
					},
					{
						sentance:
							"Stocking changes in other stores had a smaller, or negative effect. The tables below detail these changes.",
					},
				],
			},
			{
				heading: "New/Closed Stores",
				variationDirection: null,
				paragraph: "Minor positive changes",
				details: [],
			},
		],
	},
	{
		title: "Channel and Customer",
		blocks: [
			{
				heading: "Channel",
				variationDirection: null,
				paragraph: "No major shift between digital and store channels",
				details: [],
			},
			{
				heading: "Customer",
				variationDirection: null,
				paragraph: "No major change to customer generation or affluence",
				details: [],
			},
		],
	},
	{
		title: "Weather, Events and Holidays",
		blocks: [
			{
				heading: "Weather",
				paragraph: "Minor positive changes",
				variationDirection: null,
				details: [],
			},
			{
				heading: "Events",
				paragraph: "Minor positive changes",
				variationDirection: null,
				details: [],
			},
			{
				heading: "Holidays",
				paragraph: "Minor positive changes",
				variationDirection: null,
				details: [],
			},
		],
	},
];

export const salesDataPointsTop = [
	{
		label: "SALES in comparison period",
		y: 29100000,
		indexLabelFontColor: "white",
		color: "grey",
	},
	{
		label: "Impact from sales change<br />ASP Increased +$4.9 to $61.6",
		y: 2000000,
		indexLabelFontColor: "white",
	},
	{
		label: "Impact from unit change<br />UNITS decreased of -71k to 442k",
		y: -4000000,
		indexLabelFontColor: "red",
	},
	{
		label: "SALES<br />in focus period",
		y: -27200000,
		color: "grey",
		indexLabelFontColor: "white",
	},
	// { label: "Final", isCumulativeSum: true, indexLabel: "{y}", color: "#2196F3" }
];

export const salesDataPointsBottom = [
	{
		label: "",
		y: 29100000,
		indexLabelFontColor: "white",
		color: "grey",
	},
	{
		label: "Impact from <br/> SKU ASP <br/> increase",
		y: 2430000,
		indexLabelFontColor: "white",
	},
	{
		label: "Impace from sales <br/> mix change",
		y: -250000,
		indexLabelFontColor: "white",
	},
	{
		label: "",
		y: -4000000,
		color: "",
		indexLabelFontColor: "black",
	},
	{
		label: "",
		y: -27200000,
		color: "grey",
		indexLabelFontColor: "white",
	},
	// { label: "Final", isCumulativeSum: true, indexLabel: "{y}", color: "#2196F3" }
];
export const gpDataPointsTop = [
	{
		label: "GP$ in comparison period",
		y: 5200000,
		indexLabelFontColor: "white",
		color: "grey",
	},
	{
		label: "Impact from sales decrease<br />sales decreased -$1.8m",
		y: -330000,
		indexLabelFontColor: "white",
	},
	{
		label: "Impact from GP% change<br />GP% decreased of -50bps to 17.5%",
		y: -140000,
		indexLabelFontColor: "white",
	},
	{
		label: "GP $<br />in focus period",
		y: -4700000,
		color: "grey",
		indexLabelFontColor: "white",
	},
];
export const gpDataPointsBottom = [
	{
		label: "",
		y: 5200000,
		indexLabelFontColor: "white",
		color: "grey",
	},
	{
		label: "",
		y: -330000,
		indexLabelFontColor: "white",
	},
	{
		label: "Impact from <br/> COGS increase",
		y: -2160000,
		indexLabelFontColor: "white",
	},
	{
		label: "Impact from <br/> ASP increase",
		y: 2300000,
		indexLabelFontColor: "white",
	},
	{
		label: "Impact from mix <br/> change",
		y: -300000,
		indexLabelFontColor: "white",
	},
	{
		label: "",
		y: -4700000,
		color: "grey",
		indexLabelFontColor: "white",
	},
];

export const exampleQuestions = [
	"Analyse Vodka performance year to date",
	"Why did red wine underperform last week?",
	"How is beer performing across customer generations?",
	"Analyse Vodka's performance over Easter",
	"Analyse Beer's performance Year To Date",
	"Analyse Red Wine for younger generations Year To Date",
];

export const salesDataPointsTopAg = [
	{
		category: "SALES in comparison period",
		value: 29100000,
	},
	{
		category: "Impact from sales change ASP Increased +$4.9 to $61.6",
		value: 2000000,
	},
	{
		category: "Impact from unit change UNITS decreased of -71k to 442k",
		value: -4000000,
	},
	{
		category: "SALES in focus period",
		value: -27200000,
	},
	// { label: "Final", isCumulativeSum: true, indexLabel: "{y}", color: "#2196F3" }
];
