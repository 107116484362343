import React from "react";
import "./view-details-container.scss";

const ViewDetails = ({ showContent, onClick }: any) => {
	return (
		<div className="view-details" onClick={() => onClick()}>
			<div
				className={
					!showContent ? "view-details__arrow" : "view-details__arrow active"
				}
			>
				&rsaquo;
			</div>
			<div className="view-details__text">View details</div>
		</div>
	);
};

export default ViewDetails;
