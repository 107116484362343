import React, { useCallback, useState } from "react";
import "./ag-grid-static-table-container.scss";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-charts-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

const AgGridStaticTable = (props: any) => {
	const {
		gridOptions,
		gridRef,
		domLayout,
		pagination,
		paginationPageSize,
		paginationPageSizeSelector,
		columnDefs,
		defaultColDef,
		rowModelType,
		rowGroupPanelShow,
		groupDisplayType,
		serverSideSortAllLevels,
		suppressAggFuncInHeader,
		enableCharts,
		paginateChildRows,
		enableRangeSelection,
		postMasterTableAgGrid,
		transformRows,
		sideBar,
		agGridMasterApiCall,
		getRowStyle,
	} = props;

	const [rowData, setRowData] = useState([]);

	const datasource = {
		async getRows(params: any) {
			try {
				const response = agGridMasterApiCall(params.request);
				setRowData(response);
				params.success({
					rowData: response,
					lastRow: null,
				});
			} catch (error) {
				console.error("Error fetching rows:", error);
				params.fail();
			}
		},
	};

	const onGridReady = (params: any) => {
		params.api.setGridOption("serverSideDatasource", datasource);
	};

	return (
		<div style={{ width: "100%", marginTop: "32px", marginBottom: "32px" }}>
			<div style={{ width: "100%" }} className={"ag-theme-quartz"}>
				<AgGridReact
					gridOptions={gridOptions}
					ref={gridRef}
					domLayout={domLayout}
					pagination={pagination}
					paginationPageSize={paginationPageSize}
					paginationPageSizeSelector={paginationPageSizeSelector}
					columnDefs={columnDefs}
					defaultColDef={defaultColDef}
					rowModelType={rowModelType}
					onGridReady={onGridReady}
					rowGroupPanelShow={rowGroupPanelShow}
					groupDisplayType={groupDisplayType}
					serverSideSortAllLevels={serverSideSortAllLevels}
					suppressAggFuncInHeader={suppressAggFuncInHeader}
					enableCharts={enableCharts}
					paginateChildRows={paginateChildRows}
					enableRangeSelection={enableRangeSelection}
					sideBar={sideBar}
					getRowStyle={getRowStyle}
				/>
			</div>
		</div>
	);
};

export default AgGridStaticTable;
