import "./success-alert-component.scss";

const SuccessAlert = (props: any) => {
  const { message } = props;

  return (
    <div className="success-alert">
      <span className="icon">✔</span>
      <span>{message}</span>
    </div>
  );
};

export default SuccessAlert;
