import React, { useState } from "react";
import "./nav-container.scss";

import { Link } from "react-router-dom";
import chevron from "../../assets/icons/chevron-right-double.svg";
import gwLogo from "../../assets/logo/goodwork-ai_logo_black.svg";
import settingsIcon from "../../assets/icons/settings.svg";
import chatIcon from "../../assets/icons/edit.svg";
import reportIcon from "../../assets/icons/file.svg";
import profileIcon from "../../assets/icons/user-circle.svg";
import logoutIcon from "../../assets/icons/log-out.svg";

import WelcomeName from "../WelcomeName/WelcomeName";

import Button from "../Button/Button";
import { appInsights } from "../../services/app-insights.service";
import { useAuth, IsAdmin, IsSuperAdmin } from "../../context/auth.context";

const NavCollapsed = ({ setCollapseNav, collapseNav }: any) => {
	const { logout } = useAuth();

	const handleLogout = async () => {
		appInsights.trackEvent({
			name: "ButtonClicked",
			properties: {
				buttonName: "LogoutButton",
				user: `${localStorage.getItem("email")}`,
			},
		});
		await logout();
	};

	return (
		<div className="nav__collapsed-container">
			<div className="nav__collapsed-toggle-container">
				<img
					src={chevron}
					alt={"right arrow"}
					className={`nav__toggle-icon ${collapseNav ? "collapsed" : ""}`}
					onClick={() => setCollapseNav(collapseNav)}
				/>
			</div>
			<div className="nav__col-icons-container">
				<Link to="/">
					<img
						src={chatIcon}
						alt={"chat"}
						className={`nav__icon ${collapseNav ? "collapsed" : ""}`}
					/>
				</Link>

				<Link to="/report">
					<img
						src={reportIcon}
						alt={"report"}
						className={`nav__icon ${collapseNav ? "collapsed" : ""}`}
					/>
				</Link>
			</div>
			{
				IsAdmin() || IsSuperAdmin() ?
					<Link
						to="/admin"
						className={`nav__icon ${collapseNav ? "collapsed user" : ""}`}
					>
						{/* <img
							src={profileIcon}
							alt={"settings"}
							className={`nav__icon ${collapseNav ? "collapsed user-icon" : ""}`}
						/> */}
						Admin
					</Link>
					:
					''
			}
			<Link
				to="/"
				className={`nav__icon ${collapseNav ? "collapsed settings" : ""}`}
				onClick={() => handleLogout()}
			>
				<img
					src={logoutIcon}
					alt={"logout"}
					className={`nav__icon ${collapseNav ? "collapsed" : ""}`}
				/>
				<p className="nav__logout-text">Logout</p>
			</Link>
			{/* <Link
				to="/"
				className={`nav__icon ${collapseNav ? "collapsed settings" : ""}`}
			>
				<img
					src={settingsIcon}
					alt={"settings"}
					className={`nav__icon ${collapseNav ? "collapsed" : ""}`}
				/>
			</Link> */}
			<Link
				to="/"
				className={`nav__icon ${collapseNav ? "collapsed user" : ""}`}
			>
				<img
					src={profileIcon}
					alt={"settings"}
					className={`nav__icon ${collapseNav ? "collapsed user-icon" : ""}`}
				/>
			</Link>
		</div>
	);
};

const Nav = (props: any) => {
	const { setCollapseNav, collapseNav } = props;
	const { logout } = useAuth();

	const handleLogout = async () => {
		appInsights.trackEvent({
			name: "ButtonClicked",
			properties: {
				buttonName: "LogoutButton",
				user: `${localStorage.getItem("email")}`,
			},
		});
		await logout();
	};

	return (
		<>
			{!collapseNav ? (
				<div className="nav">
					<div className="nav__icons-container">
						<img src={gwLogo} alt={"goodwork logo"} className="nav__logo" />
						<img
							src={chevron}
							alt={"right arrow"}
							className="nav__toggle-icon"
							onClick={() => setCollapseNav(collapseNav)}
						/>
					</div>
					<div className="nav__block">
						<ul className="nav__list">
							<li className="nav__item">
								<Link to="/">
									<img src={chatIcon} alt={"chat"} className="nav__icon" />
									Chat
								</Link>
							</li>
							<li className="nav__item">
								<Link to="/report">
									<img src={reportIcon} alt={"report"} className="nav__icon" />
									Report
								</Link>
							</li>
							<li className="nav__item">
								<Link to="/report">
									<img
										src={settingsIcon}
										alt={"settings"}
										className="nav__icon settings"
										data-testid="gear-icon"
									/>
									Settings
								</Link>
							</li>
						</ul>
					</div>
					<div className="nav__block--user">
						<ul className="nav__list">
							<li className="nav__item--welcome">
								<WelcomeName />
							</li>
							<Button
								onClick={() => handleLogout()}
								text={"Log Out"}
								style={{ "--button-bg-color": process.env.REACT_APP_ENV_COLOR }}
							/>
						</ul>
					</div>
				</div>
			) : (
				<NavCollapsed
					setCollapseNav={setCollapseNav}
					collapseNav={collapseNav}
				/>
			)}
		</>
	);
};

export default Nav;
