import React from "react";
import "./sources-container.scss";

const Sources = ({ number }: any) => {
	return (
		<div className="sources">
			<div className="sources__text">
				{number} Sources <span className="icon">&rsaquo;</span>
			</div>
		</div>
	);
};

export default Sources;
