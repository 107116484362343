import React from "react";
import "./selection-summary-container.scss";
import SelectionBubble from "../SelectionBubble/SelectionBubble";

const SelectionSummary = ({ fieldsData }: any) => {
	const categories = [
		{ name: "product_filter", selections: [] as any[] },
		{ name: "other_filters", selections: [] as any[] },
		{ name: "time", selections: [] as any[] },
		{ name: "breakdown", selections: [] as any[] },
	];

	fieldsData?.forEach((field: any) => {
		if (field.selected !== "All") {
			const category = categories.find((cat) => cat.name === field.category[0]);
			if (category) {
				category.selections.push(field);
			}
		}
	});

	return (
		<div className="selection-summary">
			{categories.map((cat: any, index: number) => {
				const categoryHeadings: Record<string, string> = {
					product_filter: "Analyse",
					other_filters: "Filtered by",
					time: "Compare",
					breakdown: "Breakdown by",
				};

				const heading = categoryHeadings[cat.name] || "Unknown Category";

				if (
					!heading ||
					!cat.selections ||
					cat.selections.length === 0 ||
					cat.selections.every((selection: any) => !selection.selected)
				)
					return null;

				// Combine specific selections for "time"
				const combinedSelections =
					cat.name === "time"
						? combineTimeSelections(cat.selections)
						: cat.selections;

				return (
					<div className="selection-summary__bubbles" key={index}>
						<span className="selection-summary__bubbles-text">{heading}</span>

						{cat.name === "time" && combinedSelections.length === 4 ? (
							<>
								{index === 0 && (
									<span className="selection-summary__bubbles-text">
										Compare
									</span>
								)}
								<div className="selection-summary__item">
									<SelectionBubble
										icon={cat.name}
										text={combinedSelections[0].selected}
										selectedObject={combinedSelections[0]}
									/>
								</div>
								<span className="selection-summary__bubbles-text">to</span>
								<div className="selection-summary__item">
									<SelectionBubble
										icon={cat.name}
										text={combinedSelections[1].selected}
										selectedObject={combinedSelections[1]}
									/>
								</div>
							</>
						) : (
							combinedSelections.map((selection: any, idx: number) => {
								if (!selection.selected) return null;

								return (
									<div className="selection-summary__item" key={idx}>
										<SelectionBubble
											icon={cat.name}
											text={selection.selected}
											selectedObject={selection}
										/>
									</div>
								);
							})
						)}
					</div>
				);
			})}
		</div>
	);
};

const combineTimeSelections = (selections: any[]) => {
	const weekMap: Record<string, string> = {
		from_week: "to_week",
		ref_from_week: "ref_to_week",
	};

	const combined: any[] = [];
	const processed: Set<string> = new Set();

	selections.forEach((selection) => {
		// Skip already processed selections
		if (processed.has(selection.name)) return;

		const pairKey = weekMap[selection.name];
		if (pairKey) {
			// Find the pair selection
			const pair = selections.find((s) => s.name === pairKey);
			if (pair) {
				// Combine the pair and add to combined
				combined.push({
					...selection,
					selected: `Week ${selection.selected} - Week ${pair.selected}`,
				});
				// Mark both as processed
				processed.add(pairKey);
				processed.add(selection.name);
			}
		} else if (!Object.values(weekMap).includes(selection.name)) {
			// Add unpaired selection directly
			combined.push({
				...selection,
				selected: `Week ${selection.selected}`,
			});
			processed.add(selection.name);
		}
	});

	return combined;
};

export default SelectionSummary;
