import React, { useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";

const backend = process.env.REACT_APP_BE_URL || "";
function TestSignalR() {
  const [reportId, setReportId] = useState("");
  const [events, setEvents] = useState<any>([]);
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken")
  );

  useEffect(() => {
    if (!reportId) return;

    if (!accessToken) {
      console.error("No access token found. Please login first.");
      return;
    }

    // Establish a SignalR connection
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${backend}/eventHub?reportId=${reportId}`, {
        accessTokenFactory: () => accessToken,
      }) // Pass DeviceId as a query param
      .withAutomaticReconnect()
      .build();

    connection.start()
      .then(() => {
        console.log(`Connected to SignalR for Report ${reportId}`);
      })
      .catch((err) => console.error("SignalR connection error:", err));

    // Listen for new events from the backend
    connection.on("NewEvent", (event) => {
      console.log("Received new event:", event);
      setEvents((prevEvents: any) => [...prevEvents, event]);
    });

    // Cleanup on component unmount
    return () => {
      connection.stop();
    };
  }, [reportId]);

  return (
    <div style={{ padding: "20px" }}>
      <h1>Report-Specific Webhook Notifications</h1>
      <input
        type="text"
        placeholder="Enter your Report ID"
        value={reportId}
        onChange={(e) => setReportId(e.target.value)}
      />
      <ul>
        {events.length === 0 ? (
          <li>No events available for this device</li>
        ) : (
          events.map((event: any, index: any) => (
            <li key={index}>
              <strong>Event Type:</strong> {event.eventType} <br />
              <strong>Data:</strong> {JSON.stringify(event.data)}
            </li>
          ))
        )}
      </ul>
    </div>
  );
}

export default TestSignalR;
