import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPassword } from "../../services/auth.service";

import "./ResetPassword.scss";
import SubmitButton from "../../components/SubmitButton";
import SuccessAlert from "../../components/SuccessAlert";
import Logo from "../../components/Logo";

type PasswordErrorType =
  | string
  | { description: string }
  | { description: string }[]
  | { errors: { ConfirmPassword?: string[] } }
  | null;

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const userId = queryParams.get("userId");
  const token = queryParams.get("token");

  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<PasswordErrorType>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>();

  useEffect(() => {
    if (!userId || !token) {
      navigate("/login");
    }
  }, []);

  async function handleResetPassword(
    userId: string,
    token: string,
    newPassword: string,
    confirmPassword: string
  ) {
    try {
      setIsLoading(true);
      const response = await resetPassword({
        userId,
        token,
        newPassword,
        confirmPassword,
      });
      if (response.status === 200) {
        setSuccessMessage("Password has been reset successfully. Redirecting you to login ...");
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      console.error("Password change failed:", error);
      if (error.response.data) {
        setPasswordError(error.response.data);
      } else {
        setPasswordError("An unexpected error occurred. Please try again.");
      }
    }
  }

  const handlePasswordChange = (e: any) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
  };

  const handleConfirmPasswordChange = (e: any) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (
      newPassword == undefined ||
      confirmPassword == undefined ||
      userId == undefined ||
      token == undefined
    )
      return;

    await handleResetPassword(userId, token, newPassword, confirmPassword);
  };

  const hasMinLength = newPassword.length >= 8;
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);
  const hasNumber = /\d/.test(newPassword);
  const hasLowerCase = /[a-z]/.test(newPassword);
  const hasUpperCase = /[A-Z]/.test(newPassword);
  const passwordsMatch = newPassword != null && newPassword != '' && newPassword != undefined && newPassword === confirmPassword;

  const getValidationClass = (condition: boolean) => (condition ? 'valid' : 'invalid');
  const allValid = () => (hasMinLength && hasSpecialChar && hasNumber && hasLowerCase && hasUpperCase && passwordsMatch);

  return (
    <div className="reset-password-container">
      <Logo />
      {successMessage ?
        (
          <SuccessAlert message={successMessage}></SuccessAlert>
        )
        : (
          <>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="password"
                  id="password"
                  className="form-input"
                  placeholder=" "
                  onChange={(e) => handlePasswordChange(e)}
                />
                <label className="form-label">Password</label>
              </div>
              <div className="form-group">
                <input
                  type="password"
                  id="confirm"
                  className="form-input"
                  placeholder=" "
                  onChange={(e) => handleConfirmPasswordChange(e)}
                />
                <label className="form-label">Confirm Password</label>
              </div>

              <ul className="password-validation">
                <li className={getValidationClass(hasMinLength)}>✔ Minimum 8 characters</li>
                <li className={getValidationClass(hasSpecialChar)}>✔ At least one special character</li>
                <li className={getValidationClass(hasNumber)}>✔ At least one number</li>
                <li className={getValidationClass(hasLowerCase)}>✔ At least one lowercase letter</li>
                <li className={getValidationClass(hasUpperCase)}>✔ At least one uppercase letter</li>
                <li className={getValidationClass(passwordsMatch)}>✔ Passwords match</li>
              </ul>

              <SubmitButton disabled={!allValid()} isLoading={isLoading} onClick={() => handleSubmit}>
                Reset Password
              </SubmitButton>
            </form>
            <div className="reset-password__errors">
              {passwordError &&
                typeof passwordError === "object" &&
                "errors" in passwordError &&
                passwordError.errors?.ConfirmPassword && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "14px",
                      marginBottom: "12px",
                    }}
                  >
                    {passwordError.errors.ConfirmPassword.map((error, index) => (
                      <p key={index} style={{ margin: 0 }}>
                        {error}
                      </p>
                    ))}
                  </div>
                )}

              {passwordError &&
                Array.isArray(passwordError) &&
                passwordError.length > 0 && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "14px",
                      marginBottom: "12px",
                    }}
                  >
                    {passwordError.map((err, index) => (
                      <p key={index} style={{ marginBottom: "3px" }}>
                        {err.description || "An error occurred"}
                      </p>
                    ))}
                  </div>
                )}
            </div>
          </>
        )}
    </div>
  );
};

export default ResetPassword;
