import React from "react";
import "./sales-gp-container.scss";

const SalesGpSection = ({ heading, bulletPoints }: any) => {
	return (
		<div className="sales-gp">
			<div className="sales-gp__heading">{heading}</div>
			{bulletPoints &&
				bulletPoints.map((bullet: string, index: number) => {
					return (
						<li className="sales-gp__heading" key={index}>
							{bullet}
						</li>
					);
				})}
		</div>
	);
};

export default SalesGpSection;
