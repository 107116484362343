import React, { useState, useEffect } from "react";
import "./input-page-container.scss";

import ChatInput from "./ChatInput";
import DropdownConfig from "./DropdownConfig";
import { appInsights } from "../../services/app-insights.service";
import {
	postMultiSelectOptions,
} from "../../services/dashboard.service";

const InputPage = ({ chatGPTModel, setChatGPTModel }: any) => {
	const [fieldsData, setFieldsData]: any = useState(null);
	const [loading, setLoading] = useState(true);
	const [defaultWeek, setDefaultWeek] = useState(28);
	const [showSelectionsSummary, setShowSelectionsSummary] = useState(false);

	// Get Request - initial state for dropdowns

	const getFieldsInitialState = async () => {
		const params = {
			page: 1,
			pageSize: 53,
		};
		try {
			const response = await postMultiSelectOptions(params);

			setFieldsData(response.data.fields);

			setLoading(false);

			appInsights.trackEvent({
				name: "resetResponse",
				properties: {
					apiResetResponsePayload: response,
					user: `${localStorage.getItem("email")}`,
				},
			});
		} catch (error) {
			console.error("Error fetching dashboard data:", error);
		}
	};

	useEffect(() => {
		getFieldsInitialState();
	}, []);

	return (
		<div className="input-page-container">
			<ChatInput
				dropdownFieldsParent={fieldsData}
				setFieldsData={setFieldsData}
				getFieldsInitialState={getFieldsInitialState}
				loading={loading}
				setLoading={setLoading}
				chatGPTModel={chatGPTModel}
				setChatGPTModel={setChatGPTModel}
				defaultWeek={defaultWeek}
				setShowSelectionsSummary={setShowSelectionsSummary}
			/>
			<DropdownConfig
				fieldsData={fieldsData}
				setFieldsData={setFieldsData}
				loading={loading}
				showSelectionsSummary={showSelectionsSummary}
				setShowSelectionsSummary={setShowSelectionsSummary}
			/>
		</div>
	);
};

export default InputPage;
